$img: 560px;

.section-3{
    width: 100%;
    background-color: $white;
    background-image: url('../assets/images/section-3-bg-top.png');
    background-position: top;
    background-repeat: no-repeat;
    padding-top: 130px;
    overflow: hidden;
    @include min(1676){
        padding-top: 157px;
        background-size: 100%;
    }
    &--title-img{
        position: absolute;
        top: -125px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        @include media-breakpoint-down(xl){
            width: 90px;
            top: -110px;
        }
    }
   &__box-1{
       position: relative;
       background-color: $color-blue-secondary;
    margin-bottom: -130px;
    &__content{
        &__top{
            @include media-breakpoint-down(xl){
                padding-top: 10px;
             }
            p{
                font-family: $font-gothic;
                &:nth-child(2){
                    font-size: 4.9rem;
                    font-weight: 500;
                    line-height: 6rem;
                    margin: 30px 0px;
                    @include media-breakpoint-down(xl){
                        font-size: 3.9rem;
                        margin: 20px 0px;
                     }
                     @include media-breakpoint-down(xs){
                        font-size: 3.5rem;
                        line-height: 5rem;
                     }
                }
               
            }
        }
        &__bottom{
            display: flex;
            position: relative;
            height: 800px;
            @include media-breakpoint-down(xl){
                flex-direction: column;
                height: 950px;
             }
             @include media-breakpoint-down(xs){
                height: 1170px;
             }
             @include response(350){       
                height: 1250px;
             }
            
           &--1{
               z-index: 4;
            p {
                &.t-1{
                    font-size: 7rem;
                    font-weight: 400;
                    padding: 3px;
                    line-height: 1.2;
                    margin-bottom: 25px;
                    background-color: $color-yellow-primary;
                    display: inline-block;
                    @include media-breakpoint-down(xl){
                        padding: 8px;
                        font-size: 5rem;
                        margin-bottom: 20px;
                        line-height: 1;
                    }
                }
                &.t-2{
                    font-size: 4rem;
                    font-weight: 400;
                    line-height: 3.2rem;
                    margin-bottom: 35px;
                    @include media-breakpoint-down(xl){
                        font-size: 2.8rem;
                        line-height: 30px;
                       
                     }
                }
                &.t-3{
                    width: 50%;
                    // height: 340px;
                    padding: 35px 40px;
                    font-family: $font-gothic;
                    background-color: $white;
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 3.4rem;
                    text-align: justify;
                    @include media-breakpoint-down(xl){
                        font-size: 16px;
                        height: auto;
                        width: 100%;
                        margin-top: 400px;
                     }
                }
               
            }
           }
            img {
                margin-top: 40px;
                position: absolute;
                right: 0;
                width: $img;
                height: $img;
                z-index:1;
                @include media-breakpoint-down(xl){
                    right: -15%;
                    width: 480px;
                    height: 480px;
                    top: 135px;
                 }
            }
        }
    }
   }

   &__box-2{
    position: relative;
    width: 100%;
    background-color: #FAE12E;
    clip-path: polygon(100% 100%, 100% 0%, 0% 100px, 0% 100%);
    padding-top: 260px;
    padding-bottom: 200px;
    margin-bottom: -140px;
    @include min(1676){
        margin-bottom: -160px;
    }
    @include media-breakpoint-down(xl){
        padding-bottom: 150px;
        padding-top: 170px;
        clip-path: polygon(100% 100%, 100% 0%, 0% 45px, 0% 100%);
    }
    &__content{
        @include media-breakpoint-down(xl){
            padding-top: 50px;
         }
    }
    z-index: 0;
       .section-3--title-img{
           top: 90px;
       }
        z-index: 0;

        &__description{
            p{
                font-family: $font-gothic;
                line-height: 6rem;
                @include media-breakpoint-down(xl){
                    line-height: 5rem;
                 }
                &.t-1{
                    font-size: 5rem;
                    margin-top: 20px;
                    @include media-breakpoint-down(xl){
                        font-size: 3.9rem;
                     }
                     @include media-breakpoint-down(xs){
                        font-size: 3.5rem;
                     }
                }
                &.t-2{
                    font-size: 4rem;
                    @include media-breakpoint-down(xl){
                        font-size: 21px;
                        line-height:  30px ;
                        margin-top: 15px;
                     }
                }
            }
        }
        &__main{
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 600px;
            margin-top: 10px;
            @include media-breakpoint-down(xl){
                flex-direction: column;
                height: 755px;
             }
            &--absolute{
                position: absolute;
                font-size: 7rem;
                font-family: $font-gothic;
                display: inline-block;
                background-color: $color-blue-secondary;
                z-index: 9;
                line-height: 7rem;
                left: 0;
                top: 0;
                @include media-breakpoint-down(xl){
                    font-size: 5rem;
                 }
            }
            &--img{
                position: absolute;
                width: $img;
                height: $img;
                z-index:1;
                left: 0;
                top: 40px;
                @include media-breakpoint-down(xl){
                    left: -15%;
                    width: 480px;
                    height: 480px;
                 }
            }
            &--p{
                width: 484px;
                // height: 268px;
                padding: 35px 40px;
                font-family: $font-gothic;
                background-color: $white;
                font-size: 18px;
                font-weight: 300;
                line-height: 3.4rem;
                justify-self: flex-end;
                z-index: 3;
                text-align: justify;
                @include media-breakpoint-down(xl){
                    font-size: 16px;
                    height: auto;
                    width: 100%;
                 }
            }
        }

        &__checklist{
            width: 100%;
            padding: 0 50px;
            margin-top: -22px;
            @include media-breakpoint-down(xl){
                margin-top: 20px;
                padding: 0;
             }
            &--main{
                width: 100%;
                background-color: $white;
                border: 20px solid $color-blue-secondary;
                padding: 60px 90px;
                @include media-breakpoint-down(xl){
                    padding: 45px 30px;
                 }
                 @include media-breakpoint-down(xs){
                    padding: 30px;
                 }
                 @include response(340){
                    padding: 30px 15px;
                 }
                 
                &--title{
                    font-size: 3.6rem;
                    font-family: $font-gothic;
                    line-height: 6rem;
                    position: relative;
                    margin-bottom: 80px;
                    text-align: center;
                    @include media-breakpoint-down(xl){
                        font-size: 2.8rem;
                        line-height: 3.5rem;
                        margin-bottom: 45px;
                     }
                     @include media-breakpoint-down(xs){
                         font-size: 16px;
                         line-height: 24px;
                     }
                    &::after{
                        content: "";
                        width: 80px;
                        height: 10px;
                        background-color: $color-yellow-primary;
                        position: absolute;
                        top: 70px;
                        left: 50%;
                        transform: translateX(-50%);
                        @include media-breakpoint-down(xs){
                            top: 53px;
                        }
                    }
                }
                .main-content{
                    &:not(:last-child){
                        margin-bottom: 50px;
                    }
                    &--title{
                        font-family: $font-pierSans;
                       font-size: 4rem;
                       text-transform: uppercase;
                       letter-spacing: 2px;
                       margin-bottom: 20px;
                    }

                    .check-list{
                        display: flex;
                        @include media-breakpoint-down(xl){
                            display: block;
                        }
                        &--1{
                            width: 50%;
                            @include media-breakpoint-down(xl){
                                width: 100%;
                            }
                            p{
                                font-size: 16px;
                                display: flex;
                                align-items: center;
                                letter-spacing: 2px;
                                line-height: 40px;
                                @include media-breakpoint-down(xs){
                                    font-size: 14px;
                                    line-height: 30px;
                                    letter-spacing: 0;
                                }
                                img{
                                    margin-right: 10px;
                                    @include media-breakpoint-down(xs){
                                        margin-right: 5px;
                                        width: 18px;
                                        height: 18px;
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
   }
   &__box-3-wrapper{
        padding-top: 137px;
        background-image: url('../assets/images/section-3-bg-2.png');
        background-position: top;
        background-repeat: no-repeat;
        position: relative;
        z-index: 3;
        @include min(1676){
            padding-top: 157px;
            background-size: 100%;
        }

   }
   &__box-3{
    
    margin-bottom: -100px;
    position: relative;
    width: 100%;
    background-color: $color-blue-primary;
   }
}

.clip-path{
    clip-path: polygon(100% 100%, 100% 100px, 0% 0%, 0% 100%);
    position: relative;
    z-index: 4;
    padding-top: 160px!important;
    @include media-breakpoint-down(xl){
        clip-path: polygon(100% 100%, 100% 40px, 0% 0%, 0% 100%);
        padding-top: 95px!important;
    }
}

.section-3__box-3-wrapper .section-3__box-1__content__bottom{
    @include media-breakpoint-down(xl){
        height: 1145px;
     }
   
     @include media-breakpoint-down(xs){
                
        height: 1290px;
     }
    
}

