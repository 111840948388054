footer{
    .makeline-info{
        background-color: $color-yellow-primary;
        font-family: $font-gothic;
        font-size: 14px;
        line-height: 23px;
        padding-top: 120px;

        @include response(1099) {
            padding-top: 80px;
            padding-right: 5%;
            padding-left: 5%;
        }

        .text-1 {
            text-align: center;
            font-size: 2.6rem;
            font-weight: 700;
            margin-bottom: 40px;
            line-height: 1.6;

            @include response(1099) {
                font-size: 2.4rem;
            }
        }

        .info-wrapper {
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .contact-info {
            width: 100%;
            max-width: 960px;
            margin-right: auto;
            margin-left: auto;
            padding: 40px;
            background-color: #fff;
            margin-bottom: 80px;

            @include response(1099) {
                padding: 32px 24px;
            }
        }

        .info-text {
            position: relative;
            margin-bottom: 16px;

            p {
                font-size: 2rem;
                display: inline-block;
                font-weight: bold;
                padding-right: 16px;
                background-color: #fff;
                position: relative;
                z-index: 2;
            }

            &::after {
                content: ('');
                width: 100%;
                height: 1px;
                background-color: #000;
                display: block;
                position: absolute;
                top: 50%;
                z-index: 1;
            }
        }

        .text-2 {
            letter-spacing: 0.08em;
            line-height: 1.8;
            margin-bottom: 16px;
        }

        .mail-info {
            width: 80%;
            max-width: 240px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .line {
            width: 280px;
            height: 56px;
            font-size: 1.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-blue-primary;
            font-weight: 800;
            color: #fff;
            border-radius: 3px;
            letter-spacing: 0.08em;

            img {
                margin-right: 6px;
            }
        }

        .instagram-wrapper {
            width: 100%;
            max-width: 960px;
            margin: {
                right: auto;
                left: auto;
            };

            letter-spacing: 0.08em;

            .text-1 {
                font-size: 2.6rem;
                letter-spacing: 0.08em;
                margin-bottom: 8px;
            }

            .logo {
                width: 100%;
                max-width: 520px;
                height: auto;
                display: block;
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 32px;
            }

            .text-2 {
                text-align: center;
                font-weight: 600;
                font-size: 2rem;
                margin-bottom: 16px;
            }

            .instagram-banner {
                width: 100%;
                transition: all 300ms;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
    .makeline-copyright{
        background-color: $color-blue-secondary;
        padding: 13px 0;
        text-align: center;
        font-family: $font-gothic;
        font-size: 14px;
    }
}

.footer-detail {
    max-width: 960px;
    position: relative;
    padding: 40px 0;
    text-align: justify;
    @include media-breakpoint-down(xl){
        padding: 80px 0 40px 0;
    }
    .go-to-top{
        position: absolute;
        width: 88px;
        height: 88px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $color-blue-secondary;
        font-size: 14px;
        top: -44px;
        right: 0;
        @include media-breakpoint-down(xl){
            left: 50%;
            transform: translateX(-50%);
        }

    }
    img{
        margin-bottom: 9px;
        width: 30%;

        @include media-breakpoint-down(xl){
            margin-bottom: 2px;
            width: 100%;
            max-width: 200px;
        }
    }
    &--company{
        font-size: 16px;
        font-weight: bold;
    }
    a{
        color: inherit;
    }
}