.section-7{
    background-color: $color-gray-light;
    padding: 120px 0;
    position: relative;
    z-index: 0;

    @include media-breakpoint-down(xl) {
        padding: 50px 0;
    }

    &::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 800px;
        background-color: $color-yellow-primary;
        bottom: 0;
        z-index: -1;
        clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
    }
    .process-wrapper{
        margin-top: 65px;
        @include media-breakpoint-down(xl){
            margin-top: 40px;
        }
    }
    .process{
        width: 100%;
        height: 189px;
        background-color: $white;
        border-radius: 20px;
        position: relative;
        box-shadow: 5px 5px 10px rgba($black, .16);
        @include media-breakpoint-down(xl){
           padding: 40px 30px 25px 30px ;
           height: auto;
         }
        &:not(:last-child){
            margin-bottom: 60px;
            @include media-breakpoint-down(xl){
                margin-bottom: 30px;
              }
        }
        &--flag{
            position: absolute;
            background-color: $color-yellow-primary;
            width: 95px;
            transform: skew(-20deg);
            left: 20px;
            top: -10px;
            @include media-breakpoint-down(xl){
                width: 80px;
            }
            p{
                font-family:$font-pierSans;
                font-size: 1.6rem;
                color: $white;
                text-transform: uppercase;
                transform: skew(20deg);
                padding-left: 17px;
                span{
                    font-size: 3.2rem;
                    color: $color-text;
                    position: absolute;
                    top: -8px;
                    right: 9px;
                }
            }
        }
        &__main{
            display: flex;
            position: relative;
            padding: 0 30px 0 40px;
            @include media-breakpoint-down(xl){
                padding: 0 ;
             }
             @include response(420){
                 flex-direction: column;
                 justify-content: center;
                 align-items: center;
             }
            &__content{
                padding-top: 50px;
                padding-right: 40px;
                flex: 1;
                @include media-breakpoint-down(xl){
                   padding-top: 0;
                   padding-right: 0;
                }
                &--title{
                    font-size: 2.8rem;
                    margin-bottom: 9px;
                    @include response(420){
                        font-size: 24px;
                    }

                }
                &--sub{
                    font-size: 18px;
                    line-height: 1.4;
                    font-weight: 300;
                    text-align: justify;
                    @include media-breakpoint-down(xl){
                        padding-right: 40px;
                    }
                    @include response(420){
                        font-size: 16px;
                        padding-right: 0;
                        margin-bottom: 10px;
                    }
                }
                
            }
            img{
                margin-top: -20px;
                @include media-breakpoint-down(xl){
                    width: 152px;
                    height: 152px;
                    margin-top: 0;
                }
            }
        }
    }

    .support {
        position: relative;
        margin-top: 80px;
        padding: 40px 80px;
        background-color: #fff;
        border-radius: 20px;
        width: 100%;
        height: auto;

        @include media-breakpoint-down(xl){
            margin-top: 64px;
            padding: 60px 24px;
        }

        &__title {
            margin-bottom: 24px;
            text-align: center;
            font-size: 3.2rem;

            @include media-breakpoint-down(xl){
                font-size: 2.8rem;
            }
        }

        &__text {
            position: relative;
            z-index: 2;
            margin-bottom: 24px;
            font-size: 1.8rem;
            line-height: 1.6;
        }

        &__sub-title {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            font-size: 2rem;

            @include media-breakpoint-down(xl){
                margin-bottom: 0;
            }

            &::before {
                content: '';
                margin-right: 4px;
                display: block;
                border-radius: 100vh;
                background-color: $color-yellow-primary;
                width: 18px;
                height: 18px;
            }
        }

        &__content {
            margin-bottom: 40px;
            display: flex;
            gap: 2%;

            @include media-breakpoint-down(xl){
                flex-wrap: wrap;
                margin-bottom: 24px;
            }

            &__text {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                border: solid 1px #000;
                border-radius: 100vh;
                width: 23.5%;
                height: 48px;

                @include media-breakpoint-down(xl) {
                    margin-top: 16px;
                    width: 48%;
                    height: 40px;
                }

                &::before {
                    position: absolute;
                    top: 0;
                    left: 16px;
                    transform: translateY(-50%);
                    padding: 0 4px;
                    background-color: #fff;
                    font-family: 'Roboto', sans-serif;
                    font-size: 2.4rem;
                    font-weight: 500;
                    line-height: 1;
                }

                &.support-1::before {
                    content: '1';
                }

                &.support-2::before {
                    content: '2';
                }

                &.support-3::before {
                    content: '3';
                }

                &.support-4::before {
                    content: '4';
                }

                p {
                    position: relative;
                    z-index: 0;
                    padding: 0 4px;
                    width: fit-content;
                    font-size: 1.8rem;
                    font-weight: 500;

                    @include media-breakpoint-down(xl){
                        font-size: 1.5rem;
                    }

                    &::after {
                        content: '';
                        z-index: -1;
                        left: 0;
                        bottom: 0;
                        position: absolute;
                        background-color: $color-yellow-primary;
                        width: 100%;
                        height: 40%;
                    }
                }
            }
        }

        &__conversion {
            width: 100%;

            &__text {
                display: flex;
                margin: 0 auto 16px auto;
                width: fit-content;
                font-size: 1.8rem;

                @include media-breakpoint-down(xl){
                    margin: 0 auto 8px auto;
                }

                &::before {
                    content: '';
                    display: block;
                    transform: rotate(-35deg);
                    margin-right: 16px;
                    background-color: #000;
                    width: 1px;
                    height: 36px;
                }

                &::after {
                    content: '';
                    display: block;
                    transform: rotate(35deg);
                    margin-left: 16px;
                    background-color: #000;
                    width: 1px;
                    height: 36px;
                }
            }

            &__button {
                position: relative;
                z-index: 0;
                width: 100%;
                height: 64px;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    display: block;
                    border-radius: 4px;
                    z-index: -1;
                    width: 100%;
                    height: 56px;
                    background-color: #D8C228;
                }

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $color-yellow-primary;
                    border-radius: 4px;
                    width: 100%;
                    height: 56px;
                    color: #000;
                    font-size: 2rem;
                    font-weight: 900;
                }
            }
        }

        &__check {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 40px;
            transform: translateY(-56px);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FAE12E;
            border-radius: 100vh;
            width: 200px;
            height: 200px;

            @include media-breakpoint-down(xl){
                left: 0;
                transform: translateX(-15px) translateY(-50%);
                width: 95px;
                height: 95px;
            }

            p {
                position: relative;
                transform: translateY(-8px);
                line-height: 1.1;
                font-weight: 700;
                font-size: 1.8rem;

                @include media-breakpoint-down(xl){
                    transform: unset;
                    line-height: 1.2;
                    text-align: center;
                    font-size: 1.4rem;
                }

                span {
                    margin-top: 8px;
                    font-family: 'Roboto', sans-serif;
                    font-weight: 400;
                    font-size: 4.2rem;

                    @include media-breakpoint-down(xl){
                        font-size: 2.5rem;
                    }
                }

                &::after {
                    content: url('../assets/images/support-arrow.png');
                    position: absolute;
                    left: 0;
                    bottom: -4px;

                    @include media-breakpoint-down(xl){
                        display: none;
                    }
                }
            }
        }
    }
}