// scss-docs-start grid-breakpoints
$grid-breakpoints: ( xs: 435px, sm: 576px, md: 768px, lg: 992px, xl: 1001px, xxl: 1400px) !default;
// scss-docs-end grid-breakpoints

/* >= Tablet min-width: 740px */


/* PC medium resolution > min-width: 1113px */


/* Tablet - PC low resolution (min-width: 740px) and (max-width: 1023px)*/


/* > PC low resolution (min-width: 1024px) and (max-width: 1239px)*/

$sp: 1000;
$container: 1000px;
$side-width: calc((100vw - #{$container}) / 2);

$side-width-sp: 5%;
