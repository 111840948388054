// FVのブレークポイント
$xl: 1600;
$md: 1120;
$min: 1119;

.fv {
    width: 100%;
    height: 100vh;
    display: flex;

    @include response($min) {
        flex-direction: column;
        height: auto;
    }

    .fv-main {
        width: 50%;
        height: 100%;
        background-color: $color-fv-gray;
        padding: 60px;
        position: relative;
        overflow: hidden;

        @include min($xl) {
            padding: 80px;
        }

        @include response($min) {
            padding: 0;
        }

        @include response($min) {
            width: 100%;
        }

        &__content-wrapper {
            width: calc(100% + 60px);
            height: 100%;
            margin-left: -60px;
            padding-top: 60px;
            padding-right: 60px;
            padding-left: 60px;
            background-color: $white;

            @include min($xl) {
                width: calc(100% + 80px);
                margin-left: -80px;
                padding-top: 80px;
                padding-left: 80px;
                background-color: $white;
            }

            @include response($min) {
                background-color: $color-yellow-primary;
            }

            @include response($min) {
                width: 100%;
                padding-top: 40px;
                padding-right: 15px;
                padding-bottom: 80px;
                padding-left: 15px;
                margin-left: unset;
            }

            .text-1-wrapper {
                position: relative;
            }

            .text-1 {
                width: fit-content;
                width: -webkit-fit-content;
                @include NotoSanJp(700);
                font-size: 2.8rem;
                letter-spacing: 0.1em;
                position: relative;
                z-index: 2;
                
                &::after {
                    content: url('../assets/images/fv_text-1_arrow.svg');
                    margin-top: -10px;
                    display: block;
                }

                @include min($xl) {
                    margin-bottom: 8px;
                    font-size: 3.2rem;

                    &::after {
                        content: url('../assets/images/fv_text-1_arrow.svg');
                        margin-top: -8px;
                        display: block;
                    }
                }

                @include response($min) {
                    margin-right: auto;
                    margin-left: auto;
                    font-size: 2.4rem
                }
            }
            
            .fv-heading {
                @include NotoSanJp(700);
                font-size: 4rem;
                letter-spacing: 0.1em;
                line-height: 1.4;
                margin-bottom: 16px;
                position: relative;
                z-index: 2;

                @include min($xl) {
                    font-size: 4.8rem;
                }

                @include response($min) {
                    text-align: center;
                    font-size: 3rem
                }
            }

            .fv-point {
                display: flex;
                margin-bottom: 24px;

                @include response($min) {
                    justify-content: center;
                }
            }

            .flex-img {
                width: 120px;
                margin-right: 16px;

                @include response($min) {
                    width: 110px;
                }

                &:last-child {
                    margin-right: 0;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .fv-description {
                width: 100%;
                height: auto;
                margin-bottom: 40px;
                max-width: 420px;
                @include NotoSanJp(400);
                font-size: 1.6rem;
                letter-spacing: 0.1em;
                line-height: 1.6;
                text-align: justify;
                position: relative;
                z-index: 2;

                @include min($xl) {
                    font-size: 1.8rem;
                }

                @include response($min) {
                    max-width: 380px;
                    margin-right: auto;
                    margin-left: auto;
                }
            }

            .button-area {
                width: 280px;
                height: 61px;
                position: relative;
                
                @include response($min) {
                    margin-right: auto;
                    margin-left: auto;
                }

                &::after {
                    content: ('');
                    display: block;
                    width: 100%;
                    height: 56px;
                    background-color: #4BB2D8;
                    border-radius: 5px;
                    bottom: 0;
                    position: absolute;
                    bottom: 0;
                    z-index: 1;
                }

                button {
                    width: 100%;
                    height: 56px;
                    font-size: 18px;
                    letter-spacing: 0.12em;
                    @include NotoSanJp(700);
                    background-color: $color-blue-primary;
                    border-radius: 5px;
                    color: #fff;
                    position: absolute;
                    left: 0;
                    border: none;
                    z-index: 2;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 18px;
                        height: auto;
                        display: block;
                        margin-right: 6px;
                    }

                    &:hover {
                        top: 5px;
                    }
                }
            }
        }

        img.hifu-main {
            width: 460px;
            height: auto;
            position: absolute;
            top: 32%;
            right: -20%;

            @include response(1280) {
                width: 460px;
                top: 21%;
                right: -45%;
            }

            @include min($xl) {
                width: 540px;
                top: 20%;
                right: -25%;
            }

            @include min(1800) {
                width: 580px;
                top: 24%;
                right: -10%;
            }

            @include response($min) {
                display: none;
            }
        }

        img.hifu-sub {
            width: 290px;
            top: -24%;
            left: -7%;
            position: absolute;

            @include response(1180) {
                width: 280px;
                top: -22.5%;
                left: -11%;
            }

            @include min($xl) {
                width: 320px;
                top: -19%;
                left: -5%;
            }

            @include min(1800) {
                width: 350px;
                top: -23%;
                left: -5%;
            }

            @include response($min) {
                display: none;
            }
        }
    }
}

.slider {
    width: 50%;
    height: 100%;
    background-color: $color-yellow-primary;
    padding: 60px;
    position: relative;

    @include min($xl) {
        padding: 80px;
    }

    @include response($min) {
        width: 100%;
        padding: 0;
    }

    .slider-text {
        width: 100%;
        height: 100%;
        position: relative;

        &::before {
            content: ('MAKE LINE');
            position: absolute;
            font-family: 'Roboto', sans-serif;
            z-index: 1;
            font-size: 7.5rem;
            font-weight: 700;
            white-space: nowrap;
            letter-spacing: 0.1em;
            transform: translateY(-50%) translateX(-4px);

            @include response($min) {
                display: none;
            }
        }

        &::after {
            content: ('MAKE NEXT BEAUTIFUL');
            position: absolute;
            font-family: 'Roboto', sans-serif;
            z-index: 1;
            font-size: 7.5rem;
            font-weight: 700;
            white-space: nowrap;
            letter-spacing: 0.1em;
            transform: translateY(-50%) translateX(-4px);

            @include response($min) {
                display: none;
            }
        }
    }

    .slider-text-sp {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;

        @include min(1120) {
            display: none;
        }

        p {
            width: fit-content;
            font-size: 5.6rem;
            background-color: #fff;
            margin-bottom: 16px;
            padding: 6px;
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            line-height: 1;
            letter-spacing: 0.1em;

            @include response(579) {
                font-size: 2.8rem;
                margin-bottom: 8px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__display-area {
        width: 100%;
        height: 100%;
        background-color: #fff;
        overflow: hidden;
        position: relative;

        .img-1,
        .img-2,
        .img-3 {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .img-1 {
            background-image: url('../assets/images/fv-slider-1.png');
        }

        .img-2 {
            background-image: url('../assets/images/fv-slider-2.png');
        }

        .img-3 {
            background-image: url('../assets/images/fv-slider-3.png');
        }
    }
}

.slick-slide {
    height: 100%;
}

.active {
    animation-name: img-move;
    animation-duration: 14s;
    animation-timing-function: linear;
}

@keyframes img-move {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 100%;
    }
}

.campaign-eyecatch {
    width: 100%;
    height: auto;

    a {
        display: flex;
        width: 100%;
    }

    .taikenkai-banner {
        width: 100%;
    }
}