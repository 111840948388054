$gutter: 18px;
.section-4 {
    background-color: #D8E2EB;
    padding-bottom: 120px;
    
    &-part-1 {
        background-color: #D8E2EB;

        &-title {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 80px;
            margin-top: 120px;

            @include response(1099) {
                margin-top: 60px;
                
            }

            img{
                @include media-breakpoint-down(xl){
                    width: 310px;
                 }
                 @include media-breakpoint-down(xs){
                    width: 230px;
                 }
            }
            .span-1{
                font-size: 40px;
                margin-left: 10px;
                font-family: $font-notoSan;
                font-weight: bold;
                padding-top: 20px;
                @include media-breakpoint-down(xl){
                   font-size: 23px;
                   padding-top: 20px;
                 }
            }
            .span-2{
                margin-top: 32px;
                font-family: $font-notoSan;
                font-weight: bold;
                font-size: 50px;
                line-height: 13.1rem;
                @include media-breakpoint-down(xl){
                    font-size: 32px;
                  }

                  @include media-breakpoint-down(xl){
                    font-size: 22px;
                  }
            }
           
        }
        &-content {
            position: relative;
            background-color: #ffffff;
            border-radius: 50px;
            margin-top: 150px;
            @include media-breakpoint-down(xl){
                border-radius: 0;
            }
            &__header {
                z-index: 9999;
                position: relative;
                top: -150px;
                background-color: #FAE12E;
                width: 920px;
                border-bottom-right-radius: 60px;
                border-top-right-radius: 60px;
                @include media-breakpoint-down(xl){
                   width: 100%;
                   border-bottom-right-radius: 0px;
                    border-top-right-radius: 0px;
                  }
                &-point {
                    position: absolute;
                    margin-top: -100px;
                    margin-left: 24px;
                    background-color: #FAE12E;
                    width: 175px;
                    border-radius: 190px;
                    height: 30px;
                    @include media-breakpoint-down(xl){
                        margin-top: -55px;
                        margin-left: 70px;
                        width: 150px;
                       }
                       @include media-breakpoint-down(xs){
                        margin-left: 30px;
                       }
                    span {
                        font-family: $font-pierSans;
                        &:nth-child(1) {
                            position: absolute;
                            color: #ffffff;
                            font-size: 28px;
                            font-weight: bold;
                            line-height: 3.7rem;
                            margin-top: -3px;
                            margin-left: 18px;

                            @include media-breakpoint-down(xl){
                                font-size: 24px;
                                left: 12px;
                                bottom: 0;
                                margin-left: 5px;
                            }
                        }
                        &:nth-child(2) {
                            position: absolute;
                            color: #000000;
                            font-size: 52px;
                            font-weight: bold;
                            line-height: 6.8rem;
                            margin-top: -25px;
                            margin-left: 98px;
                            font-family: $font-roboto;
                            font-weight: 500;
                            @include media-breakpoint-down(xl){
                                font-size: 38px;
                                margin-top: -11px;
                                margin-left: 90px;
                                
                            }
                        }
                    }
                }
                img {
                    padding-right: 10px;
                    width: 920px;
                    // height: 340px;
                    margin-bottom: 0px;
                    padding-bottom: 0px;
                    @include media-breakpoint-down(xl){
                        width: 100%;
                        padding-right: 0;
                    }
                }
                &-text {
                    // margin-top: -20px;
                    margin-left: 80px;
                    @include media-breakpoint-down(xl){
                        padding: 24px 0;
                        margin-left: 70px;
                    }
                    @include media-breakpoint-down(xs){
                        padding: 20px 30px;
                        margin-left: 0;
                    }
                    span {
                        line-height: 8rem;
                        @include media-breakpoint-down(xl){
                            line-height: 6rem;
                        }
                        &:nth-child(1) {
                            font-family: $font-notoSan;
                            font-weight: bold;
                            font-size: 4rem;
                            @include media-breakpoint-down(xl){
                                margin-bottom: 8px;
                                line-height: 1;
                                display: block;
                                font-size: 2.8rem;
                            }
                        }
                        &:nth-child(2) {
                            font-family: $font-notoSan;
                            font-weight: bold;
                            font-size: 6rem;
                            @include media-breakpoint-down(xl){
                                display: block;
                                line-height: 1;
                                font-size: 4.2rem;
                            }
                        }
                    }
                }
            }
            &__body {
                background-color: #ffffff;
                padding: 50px 80px 80px 80px;
                border-radius: 50px;
                margin-top: -150px;
                @include media-breakpoint-down(xl){
                    padding: 40px 0px 0px 0px;
                }
                &-wrapper {
                    .row {
                        padding-bottom: 20px;
                        @include media-breakpoint-down(xl){
                            padding-bottom: 0;
                        }
                    }
                }
                &-make-line {
                    img {
                        width: 258px;
                        height: 38px;
                    }
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    margin-left: 120px;
                    .line {
                        width: 70px;
                        height: 7px;
                        background-color: #FAE12E;
                        margin-top: 5px;
                    }
                }
                &-title {
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    font-size: 30px;
                    font-family: $font-notoSan;
                    font-weight: 500;
                    margin-left: 100px;
                    .line {
                        width: 70px;
                        height: 7px;
                        background-color: #FAE12E;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    &-part-2 {
        &-content{
            margin-top:250px ;
            @include media-breakpoint-down(xl){
                margin-top: 150px;
            }
            &__header{
                border-bottom-right-radius: unset;
                border-top-right-radius: unset;
                border-top-left-radius: 60px;
                border-bottom-left-radius: 60px;
                padding-left: 10px;
                right: -80px;
                @include media-breakpoint-down(xl){
                    right: 0px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    padding-left: 0px;
                }
                &-point{
                    top: 280px;
                    left: -60px;
                    margin-top: unset;
                    @include media-breakpoint-down(xl){
                        margin-top: -55px;
                        margin-left: 70px;
                        top: auto;
                        left: auto;
                    }
                    @include media-breakpoint-down(xs){
                        margin-left: 30px;
                    }
                }
                &-text{
                    margin-right: 80px;
                    text-align: right;

                    @include media-breakpoint-down(xl){
                        margin-left: 70px;
                        text-align: unset;
                    }
                    @include media-breakpoint-down(xs){
                        margin-left: unset;
                        margin-right: unset;
                    }
                }
            }
            &__body{
                @include media-breakpoint-down(xl){
                    padding-bottom: 80px;
                }
                .content-top{
                    display: flex;
                    position: relative;
                    @include media-breakpoint-down(xl){
                        flex-direction: column;
                    }
                    &--line{
                        width: 162px;
                        height: 45px;
                        position: relative;
                        left: 50%;
                        transform: translateX(-50%);
                        margin: 20px 0;
                    }
                    &--absolute{
                        position: absolute;
                        left: 0;
                        top: 215px;
                        width: 100%;
                    }
                    &__box{
                        padding-bottom: 15px;
                        @include media-breakpoint-down(xl){
                            display: flex;
                            width: 100%!important;

                        }
                        &:not(:last-child){
                            background-image: linear-gradient(#707070 60%, rgba(255,255,255,0) 0%);
                            background-position: right;
                            background-size: 1px 10px;
                            background-repeat: repeat-y;
                            @include media-breakpoint-down(xl){
                                background-image: none;
                            }
                        }
                        &--des{
                            margin-top: 85px;
                            @include media-breakpoint-down(xl){
                                margin-top: 0;
                                margin-left: 18px;
                            }
                            p{
                                font-size: 16px;
                                font-weight: 400;
                                padding-left: 20px;
                                position: relative;
                                text-align: justify;
                                &:not(:last-child){
                                    margin-bottom: 14px;
                                }
                                &::before{
                                    content: "";
                                    width: 16px;
                                    height: 16px;
                                    border-radius: 50%;
                                    background-color: $color-yellow-primary;
                                    position: absolute;
                                    left: 0;
                                    top: 2px;
                                }
                            }
                        }
                        &--imgs{
                            display: flex; 
                            :not(:last-child){
                                margin-right: #{$gutter};
                                @include media-breakpoint-down(xl){
                                    margin-right: 0;
                                 }
                            }
                            @include media-breakpoint-down(xl){
                                flex-direction: column;
                                margin-right: 0;
                             }

                             img{
                                @include media-breakpoint-down(xl){
                                    width: 180px;
                                 }
                             }
                        }
                        &--1{
                            width: 200px;
                            padding-right: #{$gutter};
                            @include media-breakpoint-down(xl){
                                padding-right: 0;
                             }
                        }
                        &--2{
                            flex: 1;
                            padding-right: #{$gutter};
                            padding-left: #{$gutter};
                            @include media-breakpoint-down(xl){
                                padding-right: 0;
                                padding-left: 0;
                             }
                           
                        }
                        &--3{
                            padding-left: #{$gutter};
                            width: 200px;
                            @include media-breakpoint-down(xl){
                                padding-left: 0;
                             }
                        }
                    }
                }
                .content-bottom{
                    margin-top: 60px;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                    gap: 40px;
                    @include media-breakpoint-down(xl){
                        display: flex;
                        flex-direction: column;
                        padding: 0 5%;
                        gap: 0;
                        
                     }
                     img{
                        @include media-breakpoint-down(xl){
                            max-width: 100%;
                            &:not(:last-child){
                                margin-bottom: 20px;
                            }
                         }
                     }
                }
            }
        }
    }
    &-part-3 {
        margin-top: 110px;
        @include media-breakpoint-down(xl){
            margin-top: 0;
        }
        &-content__body{
            &-row{
                padding-bottom: 45px;
                &-title{
                    font-size: 30px;
                    font-weight: bold;
                    line-height: 4.5rem;
                    background-color: #95E3FF ;
                    text-align: justify;
                    @include media-breakpoint-down(xl){
                        font-size: 2.6rem;
                        line-height: 28px;
                        padding-right: 5px;
                        padding-left: 5px;
                    }
                    @include media-breakpoint-down(xs){
                        font-size: 2.3rem;

                    }
                }
                &-content{
                    padding-top: 10px;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px;
                    text-align: justify;
                    @include media-breakpoint-down(xs){
                        font-size: 16px;
                    }
                    
                }
            }
            
        }
    }
}


.hifu {
    width: 320px;
    border-radius: 15px;
    font-size: 16px;
    font-family: $font-notoSan;
    background-color: #CFEBF5;
    font-weight: 22px;
    padding: 30px 20px;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(xl){
        display: block;
        padding: 0px 40px 25px 23px;
       
    }
    @include media-breakpoint-down(xs){
        padding: 0px 15px 25px 23px;
       
    }
    .p-hifu{
        @include media-breakpoint-down(xl){
            font-size: 22px;
            font-weight: bold;
            text-align: center;
           
        }     
    }
    p{
        line-height: 23px;
        @include media-breakpoint-down(xl){
            padding-top: 22px;
        }
    }
    p:not(.p-hifu){
        font-weight: 400;
        @include media-breakpoint-down(xl){
            padding-top: 10px;
            
        }
    }
    &-4 {
       display: block;
       padding-top: 25px;
       padding-bottom: 25px;
      
       @include media-breakpoint-down(xl){
           padding-top: 0;
       }
       .flex-box{
           display: flex;
       }
        img {
            margin-top: 10px;
            max-width: 100%;
        }
    }
}

.arrow {
    &-1,
    &-2,
    &-3,
    &-4 {
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            $size: 48px;
            width: $size;
            height: $size;
            @include media-breakpoint-down(xl) {
                transform: rotate(90deg);
            }
        }
    }
}

.make-line {
    @include media-breakpoint-down(xl){
        position: relative;
        padding: 68px 15px 0px 23px;
        margin-bottom: 65px;
    }
    @include response(420){
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .make-line-img{
        position: absolute;
        width: 214px;
        height: auto;
        left: 50%;
        transform: translateX(-50%);
        top: 25px;
        margin:0!important;
    }
    &-1 {
        border-radius: 15px;
        width: 443px;
        height: 122px;
        background-color: #9BE4FF;
        display: flex;
        @include media-breakpoint-down(xl){
            height: auto;
        }
        @include response(420){
            padding-bottom: 10px;
        }
       
        p {
            font-family: $font-notoSan;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            padding: 22px;
            text-align:justify;
            @include media-breakpoint-down(xs){
               font-size: 16px;
            }
            @include response(420){
                padding: 0px 0px 0 0;
            }
        }
        img {
            
            $size: 152px;
            width: 152px;
            height: 152px;
            margin-top: -15px;
            @include media-breakpoint-down(xl){
                margin-top: 0;
                margin-bottom: -15px;
            }
            @include response(420){
                margin-top: 10px;
                margin-bottom: 0px;
            }

        }
    }
    &-2 {
        border-radius: 15px;
        background-color: #9BE4FF;
        padding-left: 20px;
        padding-top: 25px;
        padding-bottom: 28px;
        padding-right: 12px;
        display: inline-flex;
        @include media-breakpoint-down(xl){
           padding-top: 68px;
           padding-right: 15px;
           padding-bottom: 10px;
           padding-left: 23px;
        }
        p {
            width: 250px;
            font-family: $font-notoSan;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            padding-right: 17px;
            text-align: justify;
            @include media-breakpoint-down(xl){
                width: 100%;
            }
            @include media-breakpoint-down(xs){
                font-size: 16px;
                padding-right: 0;
            }
        }
        img {
            $size: 152px;
            width: 152px;
            height: 152px;
            margin-top: -15px;
            @include response(420){
                margin-top: 10px;
            }
        }
    }
    &-4 {
        border-radius: 15px;
        width: 443px;
        background-color: #9BE4FF;
        padding-top: 25px;
        padding-bottom: 25px;
        @include media-breakpoint-down(xl){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 68px;
            padding-bottom: 25px;
            padding-left: 23px;
        }
        p {
            padding-left: 25px;
            font-family: $font-notoSan;
            font-size: 18px;
            line-height: 24px;
            padding-right: 20px;
            text-align: justify;
            @include media-breakpoint-down(xl){
                padding-left: 0;
                padding-right: 0;
            }
            @include media-breakpoint-down(xs){
                font-size: 16px;
            }
        }
        img {
            margin-top: 10px;
            width: 383px;
            height: 228px;
            margin: 15px;
            @include media-breakpoint-down(xl){
                max-width: 100%;
                height: auto;
            }
        }
    }
}

.underline {
    display: inline;
    border-bottom:  3px solid #FAE12E;
    // display: inline-block;
    // position: relative;
    // &::after{
    //     content: "";
    //     position: absolute;
    //     width: 100%;
    //     height: 3px;
    //     background-color: #FAE12E;
    //     left: 0;
    //     bottom: 0;
    // }
}

.section-4-2__title {
    font-size: 3.2rem;
    font-weight: 600;
    background-color: #FAE12E;
    width: fit-content;
    padding: 8px 16px;
    margin-bottom: 24px;
    text-align: justify;
    line-height: 1.4;
}

.section-4-2__text {
    font-size: 18px;
    text-align: justify;
    margin-bottom: 40px;

    @include media-breakpoint-down(xl){
        font-size: 16px;
    }
}

.section-4-2__img {
    width: 100%;

    img {
        width: 100%;
    }
}