a.makeline-btn{
    &:hover{
        border: none;
        .makeline-btn__mask{
            top: 0;
        }
    }
}
.makeline-btn{
    color: $color-text;
    font-size: 22px;
    font-weight: bold;
    width: 404px;
    height: 73px;
    display: block;
    background-color: $color-yellow-primary;
    border-radius: 10px;
    border: 3px solid $color-blue-primary;
    position: relative;
    transition: all .1s;
    @include media-breakpoint-down(xs){
        width: 100%;
    }
    &.blue{
        background-color: $color-blue-primary;
    }
    &.yellow{
        background-color: $color-yellow-primary;
    }
    &--circle{
        border-radius: 70px;
        .makeline-btn__mask{
            border-radius: 70px;
        }

    }
    &__mask{
        transition: all .1s;
        content: "";
        width: calc(100% + 6px);
        height: 100%; 
        border: 3px solid $color-blue-primary;
        border-radius: 10px;
        position: absolute;
        top: -9px;
        left: -3px;
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 1.4;
        .inline-icon{
            margin-right: 2rem;
            @include media-breakpoint-down(xl){
                width: 28px;
                margin-right: 7px;

            }
        }
        .block-icon{
            margin-top: 15px;
            @include media-breakpoint-down(xl){
                width: 36px;
                margin-top: 7px;
            }
        }
    }
}

.large{
    height: 140px!important;
    font-size: 4rem;
    @include media-breakpoint-down(xl){
        height: 92px!important;
        font-size: 2.8rem;
     }
     @include media-breakpoint-down(xs){
        font-size: 18px;
     }
}

.middle{
    height: 130px!important;
    font-size: 4rem;
    @include media-breakpoint-down(xl){
        height: 100px!important;
        font-size: 3rem;
     }
     @include media-breakpoint-down(xs){
        font-size: 24px;
     }
}

.btn-none-border{
    border: none;
    .makeline-btn__mask{
        border: none;
        width: 100%;
        left: 0;
    }
}


// red button
button.button{
    height: 80px;
    margin-top: 60px;
}
.button {
    background-color: #DB5C51;   
    border: 6px #F0ACA8 solid; 
    display: flex;
    position: relative;
    height: 100px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all .1s;
    width: 100%;
    cursor: pointer;
    &:focus{
        outline: none;
    }
    &:hover{
        background-color: rgba(#DB5C51, .8);   
    }
    p {
        color: white;
        font-size: 24px;
        line-height: 38px;
        @include media-breakpoint-down(xl){
            font-size: 18px;
            text-align: center;
            line-height: 24px;
        }
    } 
    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 25px;
        @include media-breakpoint-down(xs){
            right: 15px;
        }
        @include response(320){
            right: 10px;
            width: 15px;
        }
    }     
}

.return-btn {
    background-color: $color-gray;
    border-color: #b5b5b5;
    &:hover{
        background-color: rgba($color-gray, .8);   
    }
    img{
        right: auto;
        left: 25px;
        transform: rotate(180deg) translateY(50%);
        @include media-breakpoint-down(xs){
            left: 15px;
        }
        @include response(320){
            left: 10px;
            width: 15px;
        }
    }
}