@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Noto+Sans+JP:wght@400;500;700&family=Roboto:wght@100;300;400;500;700&display=swap');

$font-size-root: null !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * .875 !default;
$font-size-lg: $font-size-base * 1.25 !default;
//
$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;


// メイクラインフォント
$font-normal: 1.8rem;


// Font family

$font-notoSan: 'Noto Sans JP', sans-serif;
$font-gothic: "Hiragino Kaku Gothic ProN",'源ノ角ゴシック JP', "メイリオ",'Meiryo', sans-serif;
$font-pierSans: 'Montserrat', sans-serif;
$font-pierSans-light: 'pierSans Light', sans-serif;

$font-Montserrat: 'Montserrat', sans-serif;
$font-roboto:  'Roboto', sans-serif;
