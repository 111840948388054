*,*::after,*::before{
    box-sizing: border-box;
}
html{
    font-size: 62.5%; //1rem = 16px
    @include media-breakpoint-down(md){
        font-size: 50%
    }
}

body {
    @include NotoSanJp(400);
    font-size: $font-normal;
    color: $color-text;
    background-color: $white;
    line-height: 1.2;
    overflow-x: hidden;
}

.font-28 {
    font-size: 2.8rem;
}
.font-22{
    font-size: 2.2rem;
}

.font-bold{
    font-weight: bold;
}

.width-100{
    width: 100%!important;
}

.container {
    width: #{$container};
    margin: 0 auto;
    @include response(1000){
        width: 90%;
    }
}

.container-pc{
    width: #{$container};
    margin: 0 auto;
    @include response(1000){
        width: 100%;
    }
}

.container-2 {
    width: #{$container};
    margin: 0 auto;
    @include response(1000){
        width: 80%;
    }
}

.container-sp {
    @include response(1000){
        width: 90%;
        margin: 0 auto;
    }
}

.mg-l-1{
    margin-left: 7px;
}

.flex-box{
    display: flex;
}
.flex-column-center{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.none-sp{
    @include response(1000){
        display: none!important;
    }
}
.none-pc{
    display: none!important;
    @include response(1001){
        display: block!important;
    }
}

.section-title{
    font-size: 44px;
    font-weight: 300;
    line-height: 60px;
    @include response(1001){
        font-size: 30px;
        line-height: 40px;
    }
}

.section {
    overflow: hidden;
}
