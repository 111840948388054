.section-5{
    width: 100%;
    background-color: $color-yellow-light-2;
    padding: 120px 0 80px 0;

    @include media-breakpoint-down(xl){
        padding: 50px 0 40px 0;
    }

    &--main-img{
        max-width: 100%;
        @include media-breakpoint-down(xl){
            height: 63px;
        }
        @include media-breakpoint-down(xl){
           height: 47px;
        }
    }
    
    &--title{
        font-family: $font-gothic;
        font-size: 4.2rem;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: 3px;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 24px;

        @include media-breakpoint-down(xl){
           font-size: 22px;
           margin-top: 24px;
        }
       
    }
    .logo-title {
        color: #000;
        font-size: 2.4rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
    }
    &--beauty-lala{
        margin-bottom: 24px;
        width: 40%;

        @include media-breakpoint-down(xl){
           width: 100%;
           max-width: 240px;
         }
    }
    &--description{
        margin-bottom: 32px;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        max-width: 840px;
        text-align: justify;

        @include response(1000){
            width: 100%;
            font-size: 16px;
        }

    }

    &__logo-list{
        width: 100%;
        max-width: 840px;

        &:first-child {
            margin-bottom: 24px;

            @include media-breakpoint-down(xl) {
                margin-bottom: 40px;
            }
        }

        &__description {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            padding-left: 24px;
            height: 40px;
            font-size: 24px;
            font-weight: 700;

            @include media-breakpoint-down(xl) {
                margin-bottom: 24px;
                padding-left: 16px;
                height: 32px;
            }

            &.red {
                border-left: solid 6px #DB5C51;
                color: #DB5C51;
            }

            &.blue {
                border-left: solid 6px #3E86C9;
                color: #3E86C9;
            }
        }

        &__item {
            display: flex;
            gap: 2%;

            @include media-breakpoint-down(xl) {
                flex-direction: column;
                gap: 24px;
            }

            div {
                width: 32%;

                @include media-breakpoint-down(xl){
                    width: 100%;
                }
            }

            img {
                display: flex;
                width: 100%;
                height: auto;
            }

            a {
                display: inline-block;
                margin-top: 8px;
                color: #000;
                font-size: 14px;
                line-height: 1.4;
                text-decoration: none;

                &:hover {
                    color: #DB5C51;
                }

                span {
                    font-size: 18px;
                }
            }
        }
    }

    &__youtube-video{
        width: 100%;

        iframe {
            display: flex;
            width: 100%;
            aspect-ratio: 16/9;
        }
    }
}

h3.video-title{
    margin-top: 80px;
    padding: 16px 0;
    background-color: $color-yellow-primary;
    width: 100%;
    text-align: center;
    margin-top: 80px;
    font-size: 24px;
    @include media-breakpoint-down(xl){
        margin-top: 40px;
    }
}
