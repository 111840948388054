header {
    width: 100%;
    height: 60px;
    background-color: #fff;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 99999;
    align-items: center;
    padding: {
        right: 60px;
        left: 60px;
    };
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);


    @include min(1481) {
        padding: {
            right: 80px;
            left: 80px;
        };
    }

    @include response(1269) {
        padding: {
            right: 0;
            left: 5%;
        };
    }

    @include response(439) {
        height: 56px;
    }

    .header-logo {
        width: auto;
        height: 32px;
        transform: translateY(-3px);

        @include response(1269) {
            height: 32px;
        }

        @include response(1099) {
            width: fit-content;
            height: 40px;
        }

        @include response(439) {
            height: 28px;
        }

        img {
            width: auto;
            height: 100%;
        }
    }

    .header-nav {
        width: 100%;
        width: fit-content;
        height: 100%;
        margin-left: auto;

        @include response(1269) {
            padding: {
                right: 0;
                left: 2%;
            };
        }

        ul {
            height: 100%;
            list-style: none;
            font-size: 14px;
            font-weight: 700;
            display: flex;
            align-items: center;

            @include min(1481) {
                font-size: 18px;
            }

            @include response(1099) {
                display: none;
            }
    

            li {
                margin-right: 16px;
                
                &:last-child {
                    margin-right: 0;
                }

                @include min(1481) {
                    margin-right: 24px;
                }
            }

            .contact-btn {
                display: flex;
                align-items: center;
                height: 100%;
                background-color: $color-blue-primary;
                padding-right: 24px;
                padding-left: 24px;
                
                a {
                    display: flex;
                    align-items: center;
                    color: #fff;

                    img {
                        margin-right: 6px;
                    }
                }
            }

            a {
                color: #000;
                letter-spacing: 0.1em;
            }
        }
    }

    // スマホ時の資料請求ボタン
    .sp-contact {
        display: none;

        @include response(1099) {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 24px;
            background-color: $color-blue-primary;
            width: auto;
            height: 100%;
            color: $white;
            font-weight: bold;
            font-size: 1.6rem;

            img {
                display: flex;
                margin-right: 4px;
                width: 18px;
                height: auto;
            }
        }

        @include response(439) {
            padding: 0 16px;
        }
    }
}

.popup {
    position: fixed;
    z-index: 99999;
    width: 320px;
    right: 20px;
    bottom: 20px;
    opacity: 0;
    transition: all 600ms;

    @include response(649) {
        border-radius: 20px 20px 0 0 ;
        box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.2);
        right: 0;
        bottom: 0;
        width: 100%;
    }

    &.none {
        opacity: 0 !important;
        z-index: -999;
    }

    &--close {
        position: absolute;
        top: -8px;
        right: 0;
        transform: translateY(-100%);
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.2);
        background-color: $color-yellow-primary;
        border-radius: 100vh;
        width: 32px;
        height: 32px;

        @include response(649) {
            display: none;
        }

        span:nth-child(1) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%) rotate(45deg);
            display: block;
            width: 16px;
            height: 2px;
            background-color: $black;
        }

        span:nth-child(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%) rotate(-45deg);
            display: block;
            width: 16px;
            height: 2px;
            background-color: $black;
        }
    }
    
    &--heading {
        display: none;

        @include response(649) {
            position: relative;
            display: block;
            padding: 8px 0;
            border-radius: 20px 20px 0 0;
            background-color: $color-yellow-primary;
            width: 100%;
        }

        &--content {
            margin: 0 auto;
            width: fit-content;
            min-width: 340px;
        }

        &--logo {
            display: flex;
            margin: 0 auto 4px auto;
            max-width: 240px;

            img {
                width: 100%;
                height: auto;
            }
        }

        &--text {
            font-weight: 700;
            font-size: 2.4rem;
            text-align: center;
        }
    }

    &--main {
        padding: 24px 16px;
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.2);
        border-radius: 20px;
        background-color: $white;
        width: 100%;

        @include response(649) {
            border-radius: 0;
            box-shadow: unset;
            padding: 16px 5%;
        }

        &--img {
            margin-bottom: 16px;
            display: flex;
            width: 100%;

            img {
                width: 100%;
                height: auto;
            }
        }

        &--button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-yellow-primary;
            border-radius: 5px;
            width: 100%;
            height: 48px;
            color: $black;
            font-size: 1.8rem;
            font-weight: bold;

            @include response(649) {
                border-radius: 100vh;
            }
        }
    }
}
