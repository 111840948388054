.section-1{
    background-color: $color-blue-secondary;
    padding: 120px 0 250px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%,0% 85%);
    @include media-breakpoint-down(xl){
        clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 50% 100%,0% 95%);
        padding: 50px 0 75px 0;

     }
    &__top-box{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 35px;
        p{
            line-height: 8rem;
            letter-spacing: 3px;
            @include media-breakpoint-down(xl){
               line-height: 4rem;
               letter-spacing: 0px;
            }
            img{
                @include response(480){
                    max-width: 100%;
                    width: 258px;
                 }
            }
        }
        &--img{
            margin-top: 30px;
            @include media-breakpoint-down(xl){
                max-width: 100%;
                margin-top: 15px;
             }
            
        }
    }
    &__recomments-check{
        background-color: $white;
        border-radius: 20px;
        padding: 80px 110px;
        width: 862px;
        position: relative;
        @include media-breakpoint-down(xl){
            width: 100%;
            padding: 25px;
            margin-top: 100px;
        }
       
        p{
            font-size: 3rem;
            display: flex;
            align-items: flex-start;
            @include media-breakpoint-down(xl){
               font-size: 2rem;
            }
            &:not(:last-child){
                margin-bottom: 32px;
                @include media-breakpoint-down(xl){
                    margin-bottom: 20px;
                 }
            }
            img{
                margin-right: 20px ;
                @include media-breakpoint-down(xl){
                    margin-right: 7px ;
                    width: 27px;
                    position: relative;
                    top: 3px;
                    
                 }
            }
        }
        &--girl{
            position: absolute;
            bottom: -194px;
            left: -206px;
            @include media-breakpoint-down(xl){
                width: 140px;
                height: 154px;
                left: 30px;
                top: -145px;
                z-index: -1;
             }

        }
    }
    .font-40{
        font-size: 4rem;
        @include media-breakpoint-down(xl){
            font-size: 18px;
        }
       
    }
    .font-80{
        font-size: 8rem;
        @include media-breakpoint-down(xl){
            font-size: 3.8rem;
        }
    }
}
