.section-taikenkai {
    padding: 80px 0;
    background-color: #FCF9ED;
    width: 100%;
    height: auto;

    .container {
        max-width: 840px;
    }

    &__box-1 {
        margin: 0 auto 24px auto;
        width: 420px;

        @include media-breakpoint-down(xl){
            width: 100%;
        }

        img {
            display: flex;
            width: 100%;
            height: auto;
        }

        h2 {
            margin-top: 20px;
            text-align: center;
        }
    }

    &__text-1 {
        margin-bottom: 24px;
        text-align: center;
        font-size: 1.8rem;

        @include media-breakpoint-down(xl){
            text-align: left;
        }
    }

    &__benefits {
        margin-bottom: 40px;

        h3 {
            position: relative;
            z-index: 0;
            margin: 0 auto;
            width: fit-content;
            color: #DB5C51;
            font-weight: 700;
            font-size: 32px;
            line-height: 1;

            @include media-breakpoint-down(xl) {
                font-size: 28px;
            }

            &::after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 50%;
                left: 50%;
                transform: translateX(-50%);
                background-color: #fff;
                width: calc(100% + 48px);
                height: 3px;
            }
        }

        &__detail {
            margin-top: -16px;
            margin-bottom: 8px;
            padding: 48px 24px 32px 24px;
            border-radius: 10px;
            border: solid 3px #DB5C51;
            background-color: #fff;
            width: 100%;

            @include media-breakpoint-down(xl) {
                margin-top: -14px;
                padding: 32px 12px 24px 12px;
            }

            &__text {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 22px;
                font-weight: 700;

                @include media-breakpoint-down(xl) {
                    flex-direction: column;
                    gap: 5px;
                }

                span {
                    margin: 0 4px;
                    padding: 4px;
                    font-size: 34px;
                    background-color: #fae120;
                }
            }
        }

        &__caution {
            text-align: center;
        }
    }

    &__reserve {
        width: 100%;

        &__title {
            margin-bottom: 32px;
            position: relative;
            background-color: $color-yellow-primary;
            padding: 16px;
            text-align: center;
            font-size: 2.4rem;

            &::after {
                content: '';
                right: 50%;
                bottom: -17px;
                transform: translateX(50%);
                clip-path: polygon(100% 0, 0 0, 50% 100%);
                position: absolute;
                display: block;
                background-color: $color-yellow-primary;
                width: 34px;
                height: 18px;
            }
        }

        &__text {
            margin-bottom: 16px;
            font-size: 1.8;
            line-height: 1.3;
        }

        &__button {
            display: flex;
            justify-content: space-between;
            width: 100%;

            @include media-breakpoint-down(xl){
                flex-direction: column;
            }

            a {
                position: relative;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-yellow-primary;
                border-radius: 5px;
                width: 32%;
                height: 60px;
                color: #000;
                font-size: 2rem;
                font-weight: 700;

                @include media-breakpoint-down(xl){
                    margin-bottom: 16px;
                    padding-bottom: 8px;
                    width: 100%;
                }

                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    border: 0;
                    box-shadow: 0px 8px #D8C22B;
                    border-radius: 5px;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}