.section-8{
    width: 100%;
    background-color: $white;
    padding: 130px 0 120px 0;
    @include media-breakpoint-down(xl){
        padding: 50px 0 120px 0;
    }
    .qa-wrapper{
        margin-top: 30px;
        width: 100%;
    }
    .qa-item{
        width: 100%;
        &:not(:last-child){
            margin-bottom: 40px;
        }
        &--q{
            padding-left: 88px;
            background-color: $color-yellow-primary;
            padding-top: 19px;
            height: 70px;
            font-size: 2rem;
            font-weight: bold;
            position: relative;
            text-align: justify;
            @include media-breakpoint-down(xl){
               font-size: 16px;
               padding-left: 60px;
               height: auto;
               min-height: 37px;
               padding-bottom: 10px;
               padding-top: 10px;
               padding-right: 10px;
              }
            &.light{
                background-color: #FFF084;
            }
            &::before{
                position: absolute;
                content: "q";
                text-transform: uppercase;
                font-size: $font-pierSans;
                font-size: 4rem;
                top: 15px;
                left: 35px;
                @include media-breakpoint-down(xl){
                    font-size: 3rem;
                    left: 25px;
                    top: 8px;
                }
            }
        }
        &--a{
            padding-left: 88px;
            padding-top: 19px;
            font-size: 2rem;
            font-weight: 400;
            padding-right: 50px;
            position: relative;
            text-align: justify;
            @include media-breakpoint-down(xl){
                font-size: 16px;
                padding-left: 60px;
                padding-top: 10px;
               }
               @include media-breakpoint-down(xs){
                    padding-right: 10px;
               }
            &::before{
                position: absolute;
                content: "a";
                text-transform: uppercase;
                font-size: $font-pierSans;
                font-size: 4rem;
                top: 15px;
                left: 35px;
                @include media-breakpoint-down(xl){
                    font-size: 3rem;
                    left: 25px;
                   }
            }
        }
    }
}