$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$grays: ( "100": $gray-100, "200": $gray-200, "300": $gray-300, "400": $gray-400, "500": $gray-500, "600": $gray-600, "700": $gray-700, "800": $gray-800, "900": $gray-900) !default;
// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// scss-docs-end color-variables
// scss-docs-start colors-map
$colors: ( "blue": $blue, "indigo": $indigo, "purple": $purple, "pink": $pink, "red": $red, "orange": $orange, "yellow": $yellow, "green": $green, "teal": $teal, "cyan": $cyan, "white": $white, "gray": $gray-600, "gray-dark": $gray-800) !default;
// scss-docs-end colors-map
// scss-docs-start theme-color-variables
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;
// scss-docs-end theme-color-variables



// メイクラインメインカラー

$color-fv-gray: #F5F5F5;
$color-blue-primary:#57D2FD;
$color-blue-secondary: #57D2FF;
$color-yellow-primary: #FAE12E;
$color-yellow-light: #EEDF2B;
$color-yellow-light-2: #FCF9ED;
$color-blue-dark: #3E86C9;
$color-pink: #E5CADC;
$color-blue-steel-light: #C3D1DE;
$color-blue-stell-dark: #97ABBF;
$color-orchid: #D0BCD5;
$color-light-red: #F0ACA8;
$color-red: #DB5C51;
$color-gray:#7E7E7E;
$color-text: #333333;
$color-gray-light: #F5F5F4;




