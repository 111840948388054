.section-6{
    width: 100%;
    background-color: $white;
    &__top-banner{
        width: 100%;
        height: 158px;
        background-color: $color-yellow-primary;
        display: flex;
        align-items: center;
        @include media-breakpoint-down(xl){
          height: 96px;
        }
        &__content{
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            @include media-breakpoint-down(xl){
                justify-content: center;
            }
            p{
                font-size: 6rem;
                font-weight: bold;
                font-family: $font-gothic;
                line-height: 7rem;
                @include media-breakpoint-down(xl){
                    font-size: 4rem;
                }
               
            }
            &::after{
                content: "";
                position: absolute;
                border-top: 80px solid $color-yellow-primary;
                border-left: 120px solid transparent;
                border-right: 120px solid transparent;
                bottom: -90px;
                left: 50%;
                transform: translateX(-50%);
                @include media-breakpoint-down(xl){
                    border-top: 40px solid $color-yellow-primary;
                    border-left: 80px solid transparent;
                    border-right: 80px solid transparent;
                    bottom: -50px;
                }
               
            }
        }
       
    }

    &__main{
        padding-top: 110px;
        padding-bottom: 110px;
        @include media-breakpoint-down(xl){
            padding-top: 80px;
        }
        &--item{
            border: 4px solid #D8E2EB;
            box-shadow: 10px 10px 0px #D8E2EB;
            padding: 70px 70px 40px 70px;
            position: relative;
            @include media-breakpoint-down(xl){
                padding: 40px 5% 40px 5%;
            }
            &:not(:last-child){
                margin-bottom: 80px;
            }
            &--flag{
               position: absolute;
               font-family: $font-pierSans;
               font-weight: 200;
               font-size: 4rem;
               top: -40px;
               background-color: $white;
               padding: 0 10px 0 30px;
               display: flex;
               align-items: center;
               text-align: justify;
               span{
                   font-size: 7.4rem;
                   font-family: $font-pierSans;
                   line-height: 8rem;
                   margin-left: 15px;
               }
               @include media-breakpoint-down(xl){
                padding: 0 10px 0 0px;
               }
            }
            &--title{
                font-size: 4.2rem;
                font-weight: bold;
                font-family: $font-gothic;
                margin-bottom: 30px;
                @include media-breakpoint-down(xl){
                    font-size: 2.4rem;
                    margin-bottom: 10px;
                }
            }
            &--sub{
                font-size: 18px;
                text-align: justify;
                @include media-breakpoint-down(xl){
                   font-size: 16px;
                }
            }
            &__content{
                display: flex;
                justify-content:space-between;
                align-items: center;
                margin-bottom: 25px;
                @include media-breakpoint-down(xl){
                   flex-direction: column;
                }
                
                &--1{
                    background-color: $color-yellow-primary;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 386px;
                    height: 80px;
                    border-radius: 22px;
                    @include media-breakpoint-down(xl){
                        width: 100%;
                        margin-bottom: 10px;
                    }
                    img{
                        max-width: 100%;
                    }
                }
                &--2{
                    width: 44px;
                    height: 44px;
                }
                &--3{
                    font-size: 2.6rem;
                    font-weight: bold;
                    background-color: $color-yellow-primary;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 386px;
                    height: 80px;
                    border-radius: 22px;
                    @include media-breakpoint-down(xl){
                        width: 100%;
                        margin-top: 10px;
                    }
                    @include media-breakpoint-down(xs){
                        font-size: 3rem;
                    }
                }
            }
        }
    }

    &__line{
        width: 100%;
        height: 10px;
        background-color: $color-yellow-primary;
    }

    &__performances{
        padding: 130px 0 120px 0;
        @include media-breakpoint-down(xl){
            padding: 80px 0 120px 0;
        }
       &__list{
           width: 100%;
           max-height: 300px;
           overflow: auto;
           margin-top: 40px;
           @include media-breakpoint-down(xl){
            max-height: 600px;
        }
       }
       &--item{
            display: flex;
            justify-content: space-between;
            border-top: 1px solid $color-text;
            padding: 17px 0;
            color: inherit;
            @include media-breakpoint-down(xl){
                flex-direction: column;
            }
            &:last-child{
                border-bottom: 1px solid $color-text;
            }
            p{
                line-height: 2.4rem;
            }
            &--title{
                flex-basis: 300px;
                font-size: 18px;
                font-weight: bold;
                @include media-breakpoint-down(xl){
                    flex-basis: auto;
                    margin-bottom: 15px;
                }
            }
            &--address{
                flex-basis: 222px;
                font-size: 16px;
                font-weight: 400;
                @include media-breakpoint-down(xl){
                    flex-basis: auto;
                    margin-bottom: 15px;
                }
            }
            &--tel{
                font-size: 16px;
                font-weight: 400;
                @include media-breakpoint-down(xl){
                    margin-bottom: 8px;
                }
            }
            &--btn{
                font-family: $font-pierSans;
                font-size: 14px;
                color: inherit;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $color-blue-secondary;
                width: 130px;
                height: 30px;
                text-align: center;
                border-radius: 15px;
            }
       }
    }
}