.rental {
  padding: 80px 0;
  background-color: $color-blue-primary;

  @include media-breakpoint-down(xl) {
    padding: 40px 0;
  }

  h2 {
    position: relative;
    width: fit-content;
    margin: 0 auto 40px;
    padding: 8px 16px;
    border: solid 3px $white;
    color: $white;
    font-size: 4.8rem;
    line-height: 1;

    @include media-breakpoint-down(xl) {
      margin: 0 auto 32px;
      padding: 16px 8px;
      border: solid 2px $white;
      font-size: 2.8rem;
    }

    &::before {
      content: "Rental Plan !";
      position: absolute;
      top: 0;
      left: -5px;
      transform: translateY(-95%);
      font-size: 3.2rem;
      font-family: $font-roboto;
      font-weight: 400;

      @include media-breakpoint-down(xl) {
        font-size: 2.4rem;
      }
    }
  }

  &__image {
    width: 100%;
    height: auto;

    &--01 {
      max-width: 670px;
      margin: 0 auto;
      margin-bottom: 40px;
      padding-bottom: 40px;
      border-bottom: dashed 3px $white;

      @include media-breakpoint-down(xl) {
        width: 80vw;
        padding-bottom: 32px;
        margin-bottom: 32px;
      }
    }
  }

  img {
    width: 100%;
    height: auto;

    &.pc {
      @include media-breakpoint-down(xl) {
        display: none;
      }
    }

    &.sp {
      display: none;

      @include media-breakpoint-down(xl) {
        display: block;
      }
    }
  }

  &__text {
    width: 100%;
    height: auto;
    margin-bottom: 24px;
    color: $white;

    &__text-1 {
      position: relative;
      width: fit-content;
      margin: 0 auto 4px;
      font-size: 2.8rem;
      font-weight: 700;
      font-feature-settings: "palt";
      letter-spacing: 0.08em;

      @include media-breakpoint-down(xl) {
        margin: 0 auto 8px;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 2px;
        height: 32px;
        background-color: $white;
      }

      &::before {
        top: 2px;
        left: -20px;
        transform: rotate(-35deg);
      }

      &::after {
        top: 2px;
        right: -20px;
        transform: rotate(35deg);
      }
    }

    &__text-2 {
      width: fit-content;
      margin: 0 auto 8px;
      padding-bottom: 4px;
      border-bottom: solid 8px $color-yellow-primary;
      font-size: 4rem;
      font-weight: 700;

      @include media-breakpoint-down(xl) {
        margin: 0 auto 16px;
        text-align: center;
        line-height: 1.4;
      }

      br {
        display: none;

        @include media-breakpoint-down(xl) {
          display: block;
        }
      }
    }

    &__text-3 {
      width: fit-content;
      margin: 0 auto 32px;
      font-size: 2.6rem;
      font-weight: 700;

      @include media-breakpoint-down(xl) {
        margin: 0 auto 16px;
        font-size: 2.4rem;
      }
    }

    &__text-4 {
      width: fit-content;
      margin: 0 auto;
      font-size: 3.2rem;
      font-weight: 700;

      &::after {
        content: url("../assets/images/arrow.svg");
        display: block;
        width: 72px;
        height: auto;
        margin: 32px auto 0;

        @include media-breakpoint-down(xl) {
          margin: 24px auto 0;
        }
      }
    }
  }

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 64px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 8px 0 0 #DDC93B;
    background-color: $color-yellow-primary;
    color: $black;
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: 0.08em;
  }
}