.voice {
    background-color: $white;
    width: 100%;
    height: auto;
    margin: {
        right: auto;
        left: auto;
    };
    padding-top: 80px;
    padding-bottom: 120px;

    @include response(1099) {
        width: 100%;
        max-width: unset;
    }

    .section-title {
        text-align: center;
        margin-bottom: 40px;
    }

    .voice-main {
        margin: 0 auto;
        width: 90%;
        max-width: 1000px;
        height: auto;
        display: flex;
        margin-bottom: 60px;

        @include response(1099) {
            flex-direction: column;
        }
        
        &:last-child {
            margin-bottom: 0;
        }
    }

    .voice-img {
        display: flex;
        width: 100%;
        max-width: 380px;
        line-height: 1;

        @include response(1099) {
            max-width: unset;
        }

        .img-pc {
            width: 100%;
            height: auto;

            @include response(1099) {
                display: none;
            }
        }

        .img-sp {
            width: 100%;
            height: auto;

            @include min(1100) {
                display: none;
            }
        }
    }

    .voice-description {
        width: 100%;
        height: auto;
        padding-left: 24px;
        padding-top: 20px;

        @include response(1099) {
            padding-top: 0;
            padding-left: 0;
        }

        .voice-text1 {
            font-size: 'Roboto';
            font-size: 2.4rem;
            font-weight: 700;
            letter-spacing: 0.12em;
            position: relative;
            margin-bottom: 16px;

            @include response(1099) {
                display: none;
            }
            
            &::after {
                content: ('');
                width: 100%;
                height: 1px;
                background-color: #000;
                display: block;
                position: absolute;
                top: 50%;
                z-index: -1;
            }

            p {
                display: inline-block;
                background-color: #fff;
                padding-right: 16px;
                z-index: 2;
            }
        }

        .voice-title-pc {
            width: calc(100% + 40px);
            padding: {
                top: 8px;
                right: 24px;
                bottom: 8px;
                left: 40px
            };
            font-size: 2.8rem;
            letter-spacing: 0.08em;
            font-weight: 700;
            background-color: $color-yellow-primary;
            margin-left: -40px;
            margin-bottom: 16px;
            font-feature-settings: "palt";
            line-height: 1.6;

            @include response(1099) {
                display: none;
            }
        }

        .voice-text2 {
            font-size: 1.6rem;
            line-height: 1.5;
            text-align: justify;
            margin-bottom: 24px;
            letter-spacing: 0.06em;
            
            @include response(1099) {
                padding-right: 5%;
                padding-left: 5%;
                font-size: 16px;
            }
        }

        .voice-text3 {
            font-size: 1.8rem;
            letter-spacing: 0.1em;
            text-align: right;
            font-weight: 700;

            @include response(1099) {
                padding-right: 5%;
                padding-left: 5%;
                font-size: 18px;
            }
        }

        .voice-title-sp {
            width: 100%;
            height: auto;
            padding-top: 16px;
            padding-right: 16px;
            padding-bottom: 24px;
            padding-left: 16px;
            background-color: $color-yellow-primary;
            text-align: center;
            font-weight: 700;
            font-size: 2.8rem;
            letter-spacing: 0.08em;
            line-height: 1.6;
            margin-bottom: 24px;

            @include min(1100) {
                display: none;
            }

            @include response(389) {
                font-size: 2.4rem;
            }
        }

        .voice-text-sp {
            font-family: 'Roboto', sans-serif;
            font-size: 1.8rem;
            font-weight: 700;
            position: relative;
            z-index: 2;

            &::after {
                content: ('');
                width: 100%;
                height: 1px;
                background-color: #000;
                display: block;
                position: absolute;
                top: 50%;
                z-index: -1;
            }

            p {
                display: inline;
                padding-right: 16px;
                padding-left: 16px;
                background-color: $color-yellow-primary;
            }
        }
    }
}