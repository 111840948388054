.section-2{
    background-color:$white;
    width: 100%;
    margin-top: -300px;
    padding-top: 400px;
    padding-bottom: 80px;
    @include media-breakpoint-down(xl){
        padding-bottom: 0;
        padding-top: 330px;
    }

    &__top-title{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
        p{
            font-size: 6rem;
            line-height: 6rem;
            background-color: $color-yellow-primary;
            margin-bottom: 20px;
            @include media-breakpoint-down(xl){
                font-size: 4.4rem;
            }


        }
        img {
            @include media-breakpoint-down(xl){
                width: calc(4.4rem * 6);
            }
        }
        
    }
    &--fix-img{
       margin-left: #{$side-width};
       width:  calc(100vw - #{$side-width});
       margin-bottom: 75px;
       @include media-breakpoint-down(xl){
        margin-left: $side-width-sp;
        width:  calc(100vw - #{$side-width-sp});
        margin-bottom: 20px;
     }
    }
    &__bt-main{
        &__up-list{
            justify-content: space-between;
            &--item{
                width: calc(100% / 3 - 40px);
                padding-top: 30px;
                padding-bottom: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: relative;
                background-color: $color-yellow-primary;
                &:not(:last-child){
                    margin-right: 40px;
                }
                span{
                    position: absolute;
                    font-family: $font-Montserrat;
                    font-size: 100px;
                    font-weight: 500;
                    top: -30px;
                    transform: translateY(-25%);
                }
                p{
                    font-size: 3rem;
                    text-align: center;
                    line-height: 4rem;
                    font-weight: bold;
                    margin-bottom: 25px;
                    margin-top: 25px;
                }
                &--img{
                    width: 206px;
                }
            }
        }
    }
}