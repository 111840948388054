.section-contact{
    width: 100%;
    background-color: $color-gray-light;
    padding: 130px 0;
    min-height: 600px;
    @include media-breakpoint-down(xl){
        padding: 50px 0 85px  0;
    }
    
    &--title{
        font-size: 6rem;
        line-height: 6.8rem;
        margin-bottom: 40px;

        @include media-breakpoint-down(xl){
           font-size: 4.8rem;
           line-height: 3.8rem;
        }
    }

    &__main{
        width: 840px;
        @include media-breakpoint-down(xl){
            width: 100%;
            
        }

        &--note {
            margin-bottom: 24px;
            font-size: 1.8rem;
            line-height: 1.4;
        }
    }
}

.form-group{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @include media-breakpoint-down(xl){
        margin-bottom: 20px;
    }

    &--error{
        font-size: 14px;
        color: red;
    }

    &.required {
        label{
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            &::after{
                content: "必須";
                display: block;
                margin-left: 8px;
                background-color: #DD5347;
                color: $white;
                font-size: 12px;
                padding: 4px 6px;
            }

        }
    }

    label{
        font-size: 18px;
        font-weight: bold;
        display: inline-block;
        width: fit-content;
        position: relative;
    }

    input, textarea{
        border: 1px solid #DDDDDD;
        padding: 10px;
        border-radius: 5px;
        color: $color-text;
        font-size: 16px;
        @include NotoSanJp(300);
        &::placeholder{
            font-size: 16px;
            color: #CCCCCC;
            @include NotoSanJp(300);
        }
        &:focus{
            outline: none;
        }
    }
}

.accept-privacy{
    font-size: 16px;
    text-align: center;
    margin-bottom: 35px;
    margin-top: 35px;
    a{
        color: #1571DA;
    }

    @include response(420){
        font-size: 14px;
    }
}

.form-step-1{
    &.out{
        display: none;
    }
}

.form-step-2{
    &--title{
        margin-bottom: 16px;
        text-align: center;
        font-size: 4.8rem;
        @include response(500){
            font-size: 3.2rem;
        }
    }
    display: none;
    &.in{
        display: flex;
    }
}

.confirm-form{
    width: 100%;
    background-color: $white;
    padding: 75px 40px;
    border-radius: 10px;
    margin-bottom: 40px;
    margin-top: 40px;
    @include media-breakpoint-down(xs){
        padding: 40px 20px;
        margin-top: 20px;
    }
    &--item{
        &:not(:last-child){
            margin-bottom: 20px;
        }
        p{
            &:nth-child(1){
                font-size: 18px;
                font-weight: bold;
            }
            &:nth-child(2){
                font-size: 16px;
                font-weight: 300;
                white-space: pre-line;
            }
        }
    }
}

.thanks-form{
    &__main{
        padding: 70px 60px;
        background-color: $white;
        margin-top: 40px;
        @include media-breakpoint-down(xs){
            padding: 40px 20px;
        }
    }
    
    &--title{
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        line-height: 32px;
    }
    &--p{
        font-size: 18px;
        font-weight: 300;
        margin: 16px 0;
        text-align: justify;
        line-height: 32px;
    }
    &__phone{
        &--f{
            font-size: 32px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 8px;
           
        }

        .section-contact__main--tel{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            text-align: center;
        }

        img {
            display: block;
            margin-right: 8px;
        }

        .tel {
            display: block;
            font-size: 4rem;
            font-family: $font-Montserrat;
        }

        a.tel {
            color: $black;
            text-decoration: none;
        }

        .time {
            display: block;
            margin-top: 4px;
            width: 100%;
            font-weight: 700;
        }
    }
}
.bt-l{
    margin-bottom: 40px;
    @include media-breakpoint-down(xl){
        margin-bottom: 20px;
    }
}