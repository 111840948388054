@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Roboto:wght@100;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Noto+Sans+JP:wght@400;500;700&family=Roboto:wght@100;300;400;500;700&display=swap");
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input,
select {
  vertical-align: middle; }

.grid {
  width: 100%;
  display: block;
  padding: 0; }

.grid.wide {
  max-width: 1000px;
  margin: 0 auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px; }

.row.no-gutters {
  margin-left: 0;
  margin-right: 0; }

.col {
  padding-left: 4px;
  padding-right: 4px; }

.row.no-gutters .col {
  padding-left: 0;
  padding-right: 0; }

.c-0 {
  display: none; }

.c-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.c-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.c-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.c-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.c-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.c-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.c-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.c-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.c-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.c-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.c-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.c-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.c-o-1 {
  margin-left: 8.33333%; }

.c-o-2 {
  margin-left: 16.66667%; }

.c-o-3 {
  margin-left: 25%; }

.c-o-4 {
  margin-left: 33.33333%; }

.c-o-5 {
  margin-left: 41.66667%; }

.c-o-6 {
  margin-left: 50%; }

.c-o-7 {
  margin-left: 58.33333%; }

.c-o-8 {
  margin-left: 66.66667%; }

.c-o-9 {
  margin-left: 75%; }

.c-o-10 {
  margin-left: 83.33333%; }

.c-o-11 {
  margin-left: 91.66667%; }

/* >= Tablet */
@media (min-width: 1000px) {
  .row {
    margin-left: -8px;
    margin-right: -8px; }
  .col {
    padding-left: 8px;
    padding-right: 8px; }
  .m-0 {
    display: none; }
  .m-1,
  .m-2,
  .m-3,
  .m-4,
  .m-5,
  .m-6,
  .m-7,
  .m-8,
  .m-9,
  .m-10,
  .m-11,
  .m-12 {
    display: block; }
  .m-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .m-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .m-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .m-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .m-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .m-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .m-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .m-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .m-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .m-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .m-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .m-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .m-o-1 {
    margin-left: 8.33333%; }
  .m-o-2 {
    margin-left: 16.66667%; }
  .m-o-3 {
    margin-left: 25%; }
  .m-o-4 {
    margin-left: 33.33333%; }
  .m-o-5 {
    margin-left: 41.66667%; }
  .m-o-6 {
    margin-left: 50%; }
  .m-o-7 {
    margin-left: 58.33333%; }
  .m-o-8 {
    margin-left: 66.66667%; }
  .m-o-9 {
    margin-left: 75%; }
  .m-o-10 {
    margin-left: 83.33333%; }
  .m-o-11 {
    margin-left: 91.66667%; } }

/* PC medium resolution > */
@media (min-width: 1113px) {
  .row {
    margin-left: -12px;
    margin-right: -12px; }
  .row.sm-gutter {
    margin-left: -5px;
    margin-right: -5px; }
  .col {
    padding-left: 12px;
    padding-right: 12px; }
  .row.sm-gutter .col {
    padding-left: 5px;
    padding-right: 5px; }
  .l-0 {
    display: none; }
  .l-1,
  .l-2,
  .l-2-4,
  .l-3,
  .l-4,
  .l-5,
  .l-6,
  .l-7,
  .l-8,
  .l-9,
  .l-10,
  .l-11,
  .l-12 {
    display: block; }
  .l-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .l-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .l-2-4 {
    flex: 0 0 20%;
    max-width: 20%; }
  .l-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .l-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .l-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .l-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .l-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .l-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .l-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .l-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .l-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .l-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .l-o-1 {
    margin-left: 8.33333%; }
  .l-o-2 {
    margin-left: 16.66667%; }
  .l-o-3 {
    margin-left: 25%; }
  .l-o-4 {
    margin-left: 33.33333%; }
  .l-o-5 {
    margin-left: 41.66667%; }
  .l-o-6 {
    margin-left: 50%; }
  .l-o-7 {
    margin-left: 58.33333%; }
  .l-o-8 {
    margin-left: 66.66667%; }
  .l-o-9 {
    margin-left: 75%; }
  .l-o-10 {
    margin-left: 83.33333%; }
  .l-o-11 {
    margin-left: 91.66667%; } }

/* Tablet - PC low resolution */
@media (min-width: 740px) and (max-width: 1023px) {
  .wide {
    width: 644px; } }

/* > PC low resolution */
@media (min-width: 1024px) and (max-width: 1239px) {
  .wide {
    width: 984px; }
  .wide .row {
    margin-left: -12px;
    margin-right: -12px; }
  .wide .row.sm-gutter {
    margin-left: -5px;
    margin-right: -5px; }
  .wide .col {
    padding-left: 12px;
    padding-right: 12px; }
  .wide .row.sm-gutter .col {
    padding-left: 5px;
    padding-right: 5px; }
  .wide .l-0 {
    display: none; }
  .wide .l-1,
  .wide .l-2,
  .wide .l-2-4,
  .wide .l-3,
  .wide .l-4,
  .wide .l-5,
  .wide .l-6,
  .wide .l-7,
  .wide .l-8,
  .wide .l-9,
  .wide .l-10,
  .wide .l-11,
  .wide .l-12 {
    display: block; }
  .wide .l-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .wide .l-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .wide .l-2-4 {
    flex: 0 0 20%;
    max-width: 20%; }
  .wide .l-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .wide .l-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .wide .l-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .wide .l-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .wide .l-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .wide .l-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .wide .l-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .wide .l-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .wide .l-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .wide .l-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .wide .l-o-1 {
    margin-left: 8.33333%; }
  .wide .l-o-2 {
    margin-left: 16.66667%; }
  .wide .l-o-3 {
    margin-left: 25%; }
  .wide .l-o-4 {
    margin-left: 33.33333%; }
  .wide .l-o-5 {
    margin-left: 41.66667%; }
  .wide .l-o-6 {
    margin-left: 50%; }
  .wide .l-o-7 {
    margin-left: 58.33333%; }
  .wide .l-o-8 {
    margin-left: 66.66667%; }
  .wide .l-o-9 {
    margin-left: 75%; }
  .wide .l-o-10 {
    margin-left: 83.33333%; }
  .wide .l-o-11 {
    margin-left: 91.66667%; } }

/* >= Tablet min-width: 740px */
/* PC medium resolution > min-width: 1113px */
/* Tablet - PC low resolution (min-width: 740px) and (max-width: 1023px)*/
/* > PC low resolution (min-width: 1024px) and (max-width: 1239px)*/
*, *::after, *::before {
  box-sizing: border-box; }

html {
  font-size: 62.5%; }
  @media (max-width: 767.98px) {
    html {
      font-size: 50%; } }

body {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  font-size: 1.8rem;
  color: #333333;
  background-color: #fff;
  line-height: 1.2;
  overflow-x: hidden; }

.font-28 {
  font-size: 2.8rem; }

.font-22 {
  font-size: 2.2rem; }

.font-bold {
  font-weight: bold; }

.width-100 {
  width: 100% !important; }

.container {
  width: 1000px;
  margin: 0 auto; }
  @media (max-width: 1000px) {
    .container {
      width: 90%; } }

.container-pc {
  width: 1000px;
  margin: 0 auto; }
  @media (max-width: 1000px) {
    .container-pc {
      width: 100%; } }

.container-2 {
  width: 1000px;
  margin: 0 auto; }
  @media (max-width: 1000px) {
    .container-2 {
      width: 80%; } }

@media (max-width: 1000px) {
  .container-sp {
    width: 90%;
    margin: 0 auto; } }

.mg-l-1 {
  margin-left: 7px; }

.flex-box {
  display: flex; }

.flex-column-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

@media (max-width: 1000px) {
  .none-sp {
    display: none !important; } }

.none-pc {
  display: none !important; }
  @media (max-width: 1001px) {
    .none-pc {
      display: block !important; } }

.section-title {
  font-size: 44px;
  font-weight: 300;
  line-height: 60px; }
  @media (max-width: 1001px) {
    .section-title {
      font-size: 30px;
      line-height: 40px; } }

.section {
  overflow: hidden; }

.grid {
  width: 100%;
  display: block;
  padding: 0; }

.grid.wide {
  max-width: 1000px;
  margin: 0 auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px; }

.row.no-gutters {
  margin-left: 0;
  margin-right: 0; }

.col {
  padding-left: 4px;
  padding-right: 4px; }

.row.no-gutters .col {
  padding-left: 0;
  padding-right: 0; }

.c-0 {
  display: none; }

.c-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.c-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.c-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.c-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.c-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.c-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.c-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.c-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.c-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.c-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.c-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.c-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.c-o-1 {
  margin-left: 8.33333%; }

.c-o-2 {
  margin-left: 16.66667%; }

.c-o-3 {
  margin-left: 25%; }

.c-o-4 {
  margin-left: 33.33333%; }

.c-o-5 {
  margin-left: 41.66667%; }

.c-o-6 {
  margin-left: 50%; }

.c-o-7 {
  margin-left: 58.33333%; }

.c-o-8 {
  margin-left: 66.66667%; }

.c-o-9 {
  margin-left: 75%; }

.c-o-10 {
  margin-left: 83.33333%; }

.c-o-11 {
  margin-left: 91.66667%; }

/* >= Tablet */
@media (min-width: 1000px) {
  .row {
    margin-left: -8px;
    margin-right: -8px; }
  .col {
    padding-left: 8px;
    padding-right: 8px; }
  .m-0 {
    display: none; }
  .m-1,
  .m-2,
  .m-3,
  .m-4,
  .m-5,
  .m-6,
  .m-7,
  .m-8,
  .m-9,
  .m-10,
  .m-11,
  .m-12 {
    display: block; }
  .m-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .m-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .m-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .m-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .m-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .m-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .m-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .m-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .m-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .m-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .m-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .m-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .m-o-1 {
    margin-left: 8.33333%; }
  .m-o-2 {
    margin-left: 16.66667%; }
  .m-o-3 {
    margin-left: 25%; }
  .m-o-4 {
    margin-left: 33.33333%; }
  .m-o-5 {
    margin-left: 41.66667%; }
  .m-o-6 {
    margin-left: 50%; }
  .m-o-7 {
    margin-left: 58.33333%; }
  .m-o-8 {
    margin-left: 66.66667%; }
  .m-o-9 {
    margin-left: 75%; }
  .m-o-10 {
    margin-left: 83.33333%; }
  .m-o-11 {
    margin-left: 91.66667%; } }

/* PC medium resolution > */
@media (min-width: 1113px) {
  .row {
    margin-left: -12px;
    margin-right: -12px; }
  .row.sm-gutter {
    margin-left: -5px;
    margin-right: -5px; }
  .col {
    padding-left: 12px;
    padding-right: 12px; }
  .row.sm-gutter .col {
    padding-left: 5px;
    padding-right: 5px; }
  .l-0 {
    display: none; }
  .l-1,
  .l-2,
  .l-2-4,
  .l-3,
  .l-4,
  .l-5,
  .l-6,
  .l-7,
  .l-8,
  .l-9,
  .l-10,
  .l-11,
  .l-12 {
    display: block; }
  .l-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .l-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .l-2-4 {
    flex: 0 0 20%;
    max-width: 20%; }
  .l-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .l-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .l-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .l-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .l-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .l-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .l-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .l-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .l-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .l-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .l-o-1 {
    margin-left: 8.33333%; }
  .l-o-2 {
    margin-left: 16.66667%; }
  .l-o-3 {
    margin-left: 25%; }
  .l-o-4 {
    margin-left: 33.33333%; }
  .l-o-5 {
    margin-left: 41.66667%; }
  .l-o-6 {
    margin-left: 50%; }
  .l-o-7 {
    margin-left: 58.33333%; }
  .l-o-8 {
    margin-left: 66.66667%; }
  .l-o-9 {
    margin-left: 75%; }
  .l-o-10 {
    margin-left: 83.33333%; }
  .l-o-11 {
    margin-left: 91.66667%; } }

/* Tablet - PC low resolution */
@media (min-width: 740px) and (max-width: 1023px) {
  .wide {
    width: 644px; } }

/* > PC low resolution */
@media (min-width: 1024px) and (max-width: 1239px) {
  .wide {
    width: 984px; }
  .wide .row {
    margin-left: -12px;
    margin-right: -12px; }
  .wide .row.sm-gutter {
    margin-left: -5px;
    margin-right: -5px; }
  .wide .col {
    padding-left: 12px;
    padding-right: 12px; }
  .wide .row.sm-gutter .col {
    padding-left: 5px;
    padding-right: 5px; }
  .wide .l-0 {
    display: none; }
  .wide .l-1,
  .wide .l-2,
  .wide .l-2-4,
  .wide .l-3,
  .wide .l-4,
  .wide .l-5,
  .wide .l-6,
  .wide .l-7,
  .wide .l-8,
  .wide .l-9,
  .wide .l-10,
  .wide .l-11,
  .wide .l-12 {
    display: block; }
  .wide .l-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .wide .l-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .wide .l-2-4 {
    flex: 0 0 20%;
    max-width: 20%; }
  .wide .l-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .wide .l-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .wide .l-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .wide .l-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .wide .l-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .wide .l-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .wide .l-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .wide .l-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .wide .l-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .wide .l-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .wide .l-o-1 {
    margin-left: 8.33333%; }
  .wide .l-o-2 {
    margin-left: 16.66667%; }
  .wide .l-o-3 {
    margin-left: 25%; }
  .wide .l-o-4 {
    margin-left: 33.33333%; }
  .wide .l-o-5 {
    margin-left: 41.66667%; }
  .wide .l-o-6 {
    margin-left: 50%; }
  .wide .l-o-7 {
    margin-left: 58.33333%; }
  .wide .l-o-8 {
    margin-left: 66.66667%; }
  .wide .l-o-9 {
    margin-left: 75%; }
  .wide .l-o-10 {
    margin-left: 83.33333%; }
  .wide .l-o-11 {
    margin-left: 91.66667%; } }

header {
  width: 100%;
  height: 60px;
  background-color: #fff;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 99999;
  align-items: center;
  padding-right: 60px;
  padding-left: 60px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); }
  @media (min-width: 1481px) {
    header {
      padding-right: 80px;
      padding-left: 80px; } }
  @media (max-width: 1269px) {
    header {
      padding-right: 0;
      padding-left: 5%; } }
  @media (max-width: 439px) {
    header {
      height: 56px; } }
  header .header-logo {
    width: auto;
    height: 32px;
    transform: translateY(-3px); }
    @media (max-width: 1269px) {
      header .header-logo {
        height: 32px; } }
    @media (max-width: 1099px) {
      header .header-logo {
        width: fit-content;
        height: 40px; } }
    @media (max-width: 439px) {
      header .header-logo {
        height: 28px; } }
    header .header-logo img {
      width: auto;
      height: 100%; }
  header .header-nav {
    width: 100%;
    width: fit-content;
    height: 100%;
    margin-left: auto; }
    @media (max-width: 1269px) {
      header .header-nav {
        padding-right: 0;
        padding-left: 2%; } }
    header .header-nav ul {
      height: 100%;
      list-style: none;
      font-size: 14px;
      font-weight: 700;
      display: flex;
      align-items: center; }
      @media (min-width: 1481px) {
        header .header-nav ul {
          font-size: 18px; } }
      @media (max-width: 1099px) {
        header .header-nav ul {
          display: none; } }
      header .header-nav ul li {
        margin-right: 16px; }
        header .header-nav ul li:last-child {
          margin-right: 0; }
        @media (min-width: 1481px) {
          header .header-nav ul li {
            margin-right: 24px; } }
      header .header-nav ul .contact-btn {
        display: flex;
        align-items: center;
        height: 100%;
        background-color: #57D2FD;
        padding-right: 24px;
        padding-left: 24px; }
        header .header-nav ul .contact-btn a {
          display: flex;
          align-items: center;
          color: #fff; }
          header .header-nav ul .contact-btn a img {
            margin-right: 6px; }
      header .header-nav ul a {
        color: #000;
        letter-spacing: 0.1em; }
  header .sp-contact {
    display: none; }
    @media (max-width: 1099px) {
      header .sp-contact {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 24px;
        background-color: #57D2FD;
        width: auto;
        height: 100%;
        color: #fff;
        font-weight: bold;
        font-size: 1.6rem; }
        header .sp-contact img {
          display: flex;
          margin-right: 4px;
          width: 18px;
          height: auto; } }
    @media (max-width: 439px) {
      header .sp-contact {
        padding: 0 16px; } }

.popup {
  position: fixed;
  z-index: 99999;
  width: 320px;
  right: 20px;
  bottom: 20px;
  opacity: 0;
  transition: all 600ms; }
  @media (max-width: 649px) {
    .popup {
      border-radius: 20px 20px 0 0;
      box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.2);
      right: 0;
      bottom: 0;
      width: 100%; } }
  .popup.none {
    opacity: 0 !important;
    z-index: -999; }
  .popup--close {
    position: absolute;
    top: -8px;
    right: 0;
    transform: translateY(-100%);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    background-color: #FAE12E;
    border-radius: 100vh;
    width: 32px;
    height: 32px; }
    @media (max-width: 649px) {
      .popup--close {
        display: none; } }
    .popup--close span:nth-child(1) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%) rotate(45deg);
      display: block;
      width: 16px;
      height: 2px;
      background-color: #000; }
    .popup--close span:nth-child(2) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%) rotate(-45deg);
      display: block;
      width: 16px;
      height: 2px;
      background-color: #000; }
  .popup--heading {
    display: none; }
    @media (max-width: 649px) {
      .popup--heading {
        position: relative;
        display: block;
        padding: 8px 0;
        border-radius: 20px 20px 0 0;
        background-color: #FAE12E;
        width: 100%; } }
    .popup--heading--content {
      margin: 0 auto;
      width: fit-content;
      min-width: 340px; }
    .popup--heading--logo {
      display: flex;
      margin: 0 auto 4px auto;
      max-width: 240px; }
      .popup--heading--logo img {
        width: 100%;
        height: auto; }
    .popup--heading--text {
      font-weight: 700;
      font-size: 2.4rem;
      text-align: center; }
  .popup--main {
    padding: 24px 16px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    background-color: #fff;
    width: 100%; }
    @media (max-width: 649px) {
      .popup--main {
        border-radius: 0;
        box-shadow: unset;
        padding: 16px 5%; } }
    .popup--main--img {
      margin-bottom: 16px;
      display: flex;
      width: 100%; }
      .popup--main--img img {
        width: 100%;
        height: auto; }
    .popup--main--button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #FAE12E;
      border-radius: 5px;
      width: 100%;
      height: 48px;
      color: #000;
      font-size: 1.8rem;
      font-weight: bold; }
      @media (max-width: 649px) {
        .popup--main--button {
          border-radius: 100vh; } }

footer .makeline-info {
  background-color: #FAE12E;
  font-family: "Hiragino Kaku Gothic ProN", "源ノ角ゴシック JP", "メイリオ", "Meiryo", sans-serif;
  font-size: 14px;
  line-height: 23px;
  padding-top: 120px; }
  @media (max-width: 1099px) {
    footer .makeline-info {
      padding-top: 80px;
      padding-right: 5%;
      padding-left: 5%; } }
  footer .makeline-info .text-1 {
    text-align: center;
    font-size: 2.6rem;
    font-weight: 700;
    margin-bottom: 40px;
    line-height: 1.6; }
    @media (max-width: 1099px) {
      footer .makeline-info .text-1 {
        font-size: 2.4rem; } }
  footer .makeline-info .info-wrapper {
    margin-bottom: 40px; }
    footer .makeline-info .info-wrapper:last-child {
      margin-bottom: 0; }
  footer .makeline-info .contact-info {
    width: 100%;
    max-width: 960px;
    margin-right: auto;
    margin-left: auto;
    padding: 40px;
    background-color: #fff;
    margin-bottom: 80px; }
    @media (max-width: 1099px) {
      footer .makeline-info .contact-info {
        padding: 32px 24px; } }
  footer .makeline-info .info-text {
    position: relative;
    margin-bottom: 16px; }
    footer .makeline-info .info-text p {
      font-size: 2rem;
      display: inline-block;
      font-weight: bold;
      padding-right: 16px;
      background-color: #fff;
      position: relative;
      z-index: 2; }
    footer .makeline-info .info-text::after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #000;
      display: block;
      position: absolute;
      top: 50%;
      z-index: 1; }
  footer .makeline-info .text-2 {
    letter-spacing: 0.08em;
    line-height: 1.8;
    margin-bottom: 16px; }
  footer .makeline-info .mail-info {
    width: 80%;
    max-width: 240px; }
    footer .makeline-info .mail-info img {
      width: 100%;
      height: auto; }
  footer .makeline-info .line {
    width: 280px;
    height: 56px;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #57D2FD;
    font-weight: 800;
    color: #fff;
    border-radius: 3px;
    letter-spacing: 0.08em; }
    footer .makeline-info .line img {
      margin-right: 6px; }
  footer .makeline-info .instagram-wrapper {
    width: 100%;
    max-width: 960px;
    margin-right: auto;
    margin-left: auto;
    letter-spacing: 0.08em; }
    footer .makeline-info .instagram-wrapper .text-1 {
      font-size: 2.6rem;
      letter-spacing: 0.08em;
      margin-bottom: 8px; }
    footer .makeline-info .instagram-wrapper .logo {
      width: 100%;
      max-width: 520px;
      height: auto;
      display: block;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 32px; }
    footer .makeline-info .instagram-wrapper .text-2 {
      text-align: center;
      font-weight: 600;
      font-size: 2rem;
      margin-bottom: 16px; }
    footer .makeline-info .instagram-wrapper .instagram-banner {
      width: 100%;
      transition: all 300ms; }
      footer .makeline-info .instagram-wrapper .instagram-banner:hover {
        opacity: 0.8; }

footer .makeline-copyright {
  background-color: #57D2FF;
  padding: 13px 0;
  text-align: center;
  font-family: "Hiragino Kaku Gothic ProN", "源ノ角ゴシック JP", "メイリオ", "Meiryo", sans-serif;
  font-size: 14px; }

.footer-detail {
  max-width: 960px;
  position: relative;
  padding: 40px 0;
  text-align: justify; }
  @media (max-width: 1000.98px) {
    .footer-detail {
      padding: 80px 0 40px 0; } }
  .footer-detail .go-to-top {
    position: absolute;
    width: 88px;
    height: 88px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #57D2FF;
    font-size: 14px;
    top: -44px;
    right: 0; }
    @media (max-width: 1000.98px) {
      .footer-detail .go-to-top {
        left: 50%;
        transform: translateX(-50%); } }
  .footer-detail img {
    margin-bottom: 9px;
    width: 30%; }
    @media (max-width: 1000.98px) {
      .footer-detail img {
        margin-bottom: 2px;
        width: 100%;
        max-width: 200px; } }
  .footer-detail--company {
    font-size: 16px;
    font-weight: bold; }
  .footer-detail a {
    color: inherit; }

a.makeline-btn:hover {
  border: none; }
  a.makeline-btn:hover .makeline-btn__mask {
    top: 0; }

.makeline-btn {
  color: #333333;
  font-size: 22px;
  font-weight: bold;
  width: 404px;
  height: 73px;
  display: block;
  background-color: #FAE12E;
  border-radius: 10px;
  border: 3px solid #57D2FD;
  position: relative;
  transition: all .1s; }
  @media (max-width: 434.98px) {
    .makeline-btn {
      width: 100%; } }
  .makeline-btn.blue {
    background-color: #57D2FD; }
  .makeline-btn.yellow {
    background-color: #FAE12E; }
  .makeline-btn--circle {
    border-radius: 70px; }
    .makeline-btn--circle .makeline-btn__mask {
      border-radius: 70px; }
  .makeline-btn__mask {
    transition: all .1s;
    content: "";
    width: calc(100% + 6px);
    height: 100%;
    border: 3px solid #57D2FD;
    border-radius: 10px;
    position: absolute;
    top: -9px;
    left: -3px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.4; }
    .makeline-btn__mask .inline-icon {
      margin-right: 2rem; }
      @media (max-width: 1000.98px) {
        .makeline-btn__mask .inline-icon {
          width: 28px;
          margin-right: 7px; } }
    .makeline-btn__mask .block-icon {
      margin-top: 15px; }
      @media (max-width: 1000.98px) {
        .makeline-btn__mask .block-icon {
          width: 36px;
          margin-top: 7px; } }

.large {
  height: 140px !important;
  font-size: 4rem; }
  @media (max-width: 1000.98px) {
    .large {
      height: 92px !important;
      font-size: 2.8rem; } }
  @media (max-width: 434.98px) {
    .large {
      font-size: 18px; } }

.middle {
  height: 130px !important;
  font-size: 4rem; }
  @media (max-width: 1000.98px) {
    .middle {
      height: 100px !important;
      font-size: 3rem; } }
  @media (max-width: 434.98px) {
    .middle {
      font-size: 24px; } }

.btn-none-border {
  border: none; }
  .btn-none-border .makeline-btn__mask {
    border: none;
    width: 100%;
    left: 0; }

button.button {
  height: 80px;
  margin-top: 60px; }

.button {
  background-color: #DB5C51;
  border: 6px #F0ACA8 solid;
  display: flex;
  position: relative;
  height: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all .1s;
  width: 100%;
  cursor: pointer; }
  .button:focus {
    outline: none; }
  .button:hover {
    background-color: rgba(219, 92, 81, 0.8); }
  .button p {
    color: white;
    font-size: 24px;
    line-height: 38px; }
    @media (max-width: 1000.98px) {
      .button p {
        font-size: 18px;
        text-align: center;
        line-height: 24px; } }
  .button img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 25px; }
    @media (max-width: 434.98px) {
      .button img {
        right: 15px; } }
    @media (max-width: 320px) {
      .button img {
        right: 10px;
        width: 15px; } }

.return-btn {
  background-color: #7E7E7E;
  border-color: #b5b5b5; }
  .return-btn:hover {
    background-color: rgba(126, 126, 126, 0.8); }
  .return-btn img {
    right: auto;
    left: 25px;
    transform: rotate(180deg) translateY(50%); }
    @media (max-width: 434.98px) {
      .return-btn img {
        left: 15px; } }
    @media (max-width: 320px) {
      .return-btn img {
        left: 10px;
        width: 15px; } }

.fv {
  width: 100%;
  height: 100vh;
  display: flex; }
  @media (max-width: 1119px) {
    .fv {
      flex-direction: column;
      height: auto; } }
  .fv .fv-main {
    width: 50%;
    height: 100%;
    background-color: #F5F5F5;
    padding: 60px;
    position: relative;
    overflow: hidden; }
    @media (min-width: 1600px) {
      .fv .fv-main {
        padding: 80px; } }
    @media (max-width: 1119px) {
      .fv .fv-main {
        padding: 0; } }
    @media (max-width: 1119px) {
      .fv .fv-main {
        width: 100%; } }
    .fv .fv-main__content-wrapper {
      width: calc(100% + 60px);
      height: 100%;
      margin-left: -60px;
      padding-top: 60px;
      padding-right: 60px;
      padding-left: 60px;
      background-color: #fff; }
      @media (min-width: 1600px) {
        .fv .fv-main__content-wrapper {
          width: calc(100% + 80px);
          margin-left: -80px;
          padding-top: 80px;
          padding-left: 80px;
          background-color: #fff; } }
      @media (max-width: 1119px) {
        .fv .fv-main__content-wrapper {
          background-color: #FAE12E; } }
      @media (max-width: 1119px) {
        .fv .fv-main__content-wrapper {
          width: 100%;
          padding-top: 40px;
          padding-right: 15px;
          padding-bottom: 80px;
          padding-left: 15px;
          margin-left: unset; } }
      .fv .fv-main__content-wrapper .text-1-wrapper {
        position: relative; }
      .fv .fv-main__content-wrapper .text-1 {
        width: fit-content;
        width: -webkit-fit-content;
        font-family: "Noto Sans JP", sans-serif;
        font-weight: 700;
        font-size: 2.8rem;
        letter-spacing: 0.1em;
        position: relative;
        z-index: 2; }
        .fv .fv-main__content-wrapper .text-1::after {
          content: url("../assets/images/fv_text-1_arrow.svg");
          margin-top: -10px;
          display: block; }
        @media (min-width: 1600px) {
          .fv .fv-main__content-wrapper .text-1 {
            margin-bottom: 8px;
            font-size: 3.2rem; }
            .fv .fv-main__content-wrapper .text-1::after {
              content: url("../assets/images/fv_text-1_arrow.svg");
              margin-top: -8px;
              display: block; } }
        @media (max-width: 1119px) {
          .fv .fv-main__content-wrapper .text-1 {
            margin-right: auto;
            margin-left: auto;
            font-size: 2.4rem; } }
      .fv .fv-main__content-wrapper .fv-heading {
        font-family: "Noto Sans JP", sans-serif;
        font-weight: 700;
        font-size: 4rem;
        letter-spacing: 0.1em;
        line-height: 1.4;
        margin-bottom: 16px;
        position: relative;
        z-index: 2; }
        @media (min-width: 1600px) {
          .fv .fv-main__content-wrapper .fv-heading {
            font-size: 4.8rem; } }
        @media (max-width: 1119px) {
          .fv .fv-main__content-wrapper .fv-heading {
            text-align: center;
            font-size: 3rem; } }
      .fv .fv-main__content-wrapper .fv-point {
        display: flex;
        margin-bottom: 24px; }
        @media (max-width: 1119px) {
          .fv .fv-main__content-wrapper .fv-point {
            justify-content: center; } }
      .fv .fv-main__content-wrapper .flex-img {
        width: 120px;
        margin-right: 16px; }
        @media (max-width: 1119px) {
          .fv .fv-main__content-wrapper .flex-img {
            width: 110px; } }
        .fv .fv-main__content-wrapper .flex-img:last-child {
          margin-right: 0; }
        .fv .fv-main__content-wrapper .flex-img img {
          width: 100%;
          height: auto; }
      .fv .fv-main__content-wrapper .fv-description {
        width: 100%;
        height: auto;
        margin-bottom: 40px;
        max-width: 420px;
        font-family: "Noto Sans JP", sans-serif;
        font-weight: 400;
        font-size: 1.6rem;
        letter-spacing: 0.1em;
        line-height: 1.6;
        text-align: justify;
        position: relative;
        z-index: 2; }
        @media (min-width: 1600px) {
          .fv .fv-main__content-wrapper .fv-description {
            font-size: 1.8rem; } }
        @media (max-width: 1119px) {
          .fv .fv-main__content-wrapper .fv-description {
            max-width: 380px;
            margin-right: auto;
            margin-left: auto; } }
      .fv .fv-main__content-wrapper .button-area {
        width: 280px;
        height: 61px;
        position: relative; }
        @media (max-width: 1119px) {
          .fv .fv-main__content-wrapper .button-area {
            margin-right: auto;
            margin-left: auto; } }
        .fv .fv-main__content-wrapper .button-area::after {
          content: "";
          display: block;
          width: 100%;
          height: 56px;
          background-color: #4BB2D8;
          border-radius: 5px;
          bottom: 0;
          position: absolute;
          bottom: 0;
          z-index: 1; }
        .fv .fv-main__content-wrapper .button-area button {
          width: 100%;
          height: 56px;
          font-size: 18px;
          letter-spacing: 0.12em;
          font-family: "Noto Sans JP", sans-serif;
          font-weight: 700;
          background-color: #57D2FD;
          border-radius: 5px;
          color: #fff;
          position: absolute;
          left: 0;
          border: none;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center; }
          .fv .fv-main__content-wrapper .button-area button img {
            width: 18px;
            height: auto;
            display: block;
            margin-right: 6px; }
          .fv .fv-main__content-wrapper .button-area button:hover {
            top: 5px; }
    .fv .fv-main img.hifu-main {
      width: 460px;
      height: auto;
      position: absolute;
      top: 32%;
      right: -20%; }
      @media (max-width: 1280px) {
        .fv .fv-main img.hifu-main {
          width: 460px;
          top: 21%;
          right: -45%; } }
      @media (min-width: 1600px) {
        .fv .fv-main img.hifu-main {
          width: 540px;
          top: 20%;
          right: -25%; } }
      @media (min-width: 1800px) {
        .fv .fv-main img.hifu-main {
          width: 580px;
          top: 24%;
          right: -10%; } }
      @media (max-width: 1119px) {
        .fv .fv-main img.hifu-main {
          display: none; } }
    .fv .fv-main img.hifu-sub {
      width: 290px;
      top: -24%;
      left: -7%;
      position: absolute; }
      @media (max-width: 1180px) {
        .fv .fv-main img.hifu-sub {
          width: 280px;
          top: -22.5%;
          left: -11%; } }
      @media (min-width: 1600px) {
        .fv .fv-main img.hifu-sub {
          width: 320px;
          top: -19%;
          left: -5%; } }
      @media (min-width: 1800px) {
        .fv .fv-main img.hifu-sub {
          width: 350px;
          top: -23%;
          left: -5%; } }
      @media (max-width: 1119px) {
        .fv .fv-main img.hifu-sub {
          display: none; } }

.slider {
  width: 50%;
  height: 100%;
  background-color: #FAE12E;
  padding: 60px;
  position: relative; }
  @media (min-width: 1600px) {
    .slider {
      padding: 80px; } }
  @media (max-width: 1119px) {
    .slider {
      width: 100%;
      padding: 0; } }
  .slider .slider-text {
    width: 100%;
    height: 100%;
    position: relative; }
    .slider .slider-text::before {
      content: "MAKE LINE";
      position: absolute;
      font-family: 'Roboto', sans-serif;
      z-index: 1;
      font-size: 7.5rem;
      font-weight: 700;
      white-space: nowrap;
      letter-spacing: 0.1em;
      transform: translateY(-50%) translateX(-4px); }
      @media (max-width: 1119px) {
        .slider .slider-text::before {
          display: none; } }
    .slider .slider-text::after {
      content: "MAKE NEXT BEAUTIFUL";
      position: absolute;
      font-family: 'Roboto', sans-serif;
      z-index: 1;
      font-size: 7.5rem;
      font-weight: 700;
      white-space: nowrap;
      letter-spacing: 0.1em;
      transform: translateY(-50%) translateX(-4px); }
      @media (max-width: 1119px) {
        .slider .slider-text::after {
          display: none; } }
  .slider .slider-text-sp {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column; }
    @media (min-width: 1120px) {
      .slider .slider-text-sp {
        display: none; } }
    .slider .slider-text-sp p {
      width: fit-content;
      font-size: 5.6rem;
      background-color: #fff;
      margin-bottom: 16px;
      padding: 6px;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 0.1em; }
      @media (max-width: 579px) {
        .slider .slider-text-sp p {
          font-size: 2.8rem;
          margin-bottom: 8px; } }
      .slider .slider-text-sp p:last-child {
        margin-bottom: 0; }
  .slider__display-area {
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    position: relative; }
    .slider__display-area .img-1,
    .slider__display-area .img-2,
    .slider__display-area .img-3 {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat; }
    .slider__display-area .img-1 {
      background-image: url("../assets/images/fv-slider-1.png"); }
    .slider__display-area .img-2 {
      background-image: url("../assets/images/fv-slider-2.png"); }
    .slider__display-area .img-3 {
      background-image: url("../assets/images/fv-slider-3.png"); }

.slick-slide {
  height: 100%; }

.active {
  animation-name: img-move;
  animation-duration: 14s;
  animation-timing-function: linear; }

@keyframes img-move {
  0% {
    background-position-x: 0; }
  100% {
    background-position-x: 100%; } }

.campaign-eyecatch {
  width: 100%;
  height: auto; }
  .campaign-eyecatch a {
    display: flex;
    width: 100%; }
  .campaign-eyecatch .taikenkai-banner {
    width: 100%; }

.section-1 {
  background-color: #57D2FF;
  padding: 120px 0 250px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%, 0% 85%); }
  @media (max-width: 1000.98px) {
    .section-1 {
      clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 50% 100%, 0% 95%);
      padding: 50px 0 75px 0; } }
  .section-1__top-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 35px; }
    .section-1__top-box p {
      line-height: 8rem;
      letter-spacing: 3px; }
      @media (max-width: 1000.98px) {
        .section-1__top-box p {
          line-height: 4rem;
          letter-spacing: 0px; } }
      @media (max-width: 480px) {
        .section-1__top-box p img {
          max-width: 100%;
          width: 258px; } }
    .section-1__top-box--img {
      margin-top: 30px; }
      @media (max-width: 1000.98px) {
        .section-1__top-box--img {
          max-width: 100%;
          margin-top: 15px; } }
  .section-1__recomments-check {
    background-color: #fff;
    border-radius: 20px;
    padding: 80px 110px;
    width: 862px;
    position: relative; }
    @media (max-width: 1000.98px) {
      .section-1__recomments-check {
        width: 100%;
        padding: 25px;
        margin-top: 100px; } }
    .section-1__recomments-check p {
      font-size: 3rem;
      display: flex;
      align-items: flex-start; }
      @media (max-width: 1000.98px) {
        .section-1__recomments-check p {
          font-size: 2rem; } }
      .section-1__recomments-check p:not(:last-child) {
        margin-bottom: 32px; }
        @media (max-width: 1000.98px) {
          .section-1__recomments-check p:not(:last-child) {
            margin-bottom: 20px; } }
      .section-1__recomments-check p img {
        margin-right: 20px; }
        @media (max-width: 1000.98px) {
          .section-1__recomments-check p img {
            margin-right: 7px;
            width: 27px;
            position: relative;
            top: 3px; } }
    .section-1__recomments-check--girl {
      position: absolute;
      bottom: -194px;
      left: -206px; }
      @media (max-width: 1000.98px) {
        .section-1__recomments-check--girl {
          width: 140px;
          height: 154px;
          left: 30px;
          top: -145px;
          z-index: -1; } }
  .section-1 .font-40 {
    font-size: 4rem; }
    @media (max-width: 1000.98px) {
      .section-1 .font-40 {
        font-size: 18px; } }
  .section-1 .font-80 {
    font-size: 8rem; }
    @media (max-width: 1000.98px) {
      .section-1 .font-80 {
        font-size: 3.8rem; } }

.section-2 {
  background-color: #fff;
  width: 100%;
  margin-top: -300px;
  padding-top: 400px;
  padding-bottom: 80px; }
  @media (max-width: 1000.98px) {
    .section-2 {
      padding-bottom: 0;
      padding-top: 330px; } }
  .section-2__top-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px; }
    .section-2__top-title p {
      font-size: 6rem;
      line-height: 6rem;
      background-color: #FAE12E;
      margin-bottom: 20px; }
      @media (max-width: 1000.98px) {
        .section-2__top-title p {
          font-size: 4.4rem; } }
    @media (max-width: 1000.98px) {
      .section-2__top-title img {
        width: calc(4.4rem * 6); } }
  .section-2--fix-img {
    margin-left: calc((100vw - 1000px) / 2);
    width: calc(100vw - calc((100vw - 1000px) / 2));
    margin-bottom: 75px; }
    @media (max-width: 1000.98px) {
      .section-2--fix-img {
        margin-left: 5%;
        width: calc(100vw - 5%);
        margin-bottom: 20px; } }
  .section-2__bt-main__up-list {
    justify-content: space-between; }
    .section-2__bt-main__up-list--item {
      width: calc(100% / 3 - 40px);
      padding-top: 30px;
      padding-bottom: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      background-color: #FAE12E; }
      .section-2__bt-main__up-list--item:not(:last-child) {
        margin-right: 40px; }
      .section-2__bt-main__up-list--item span {
        position: absolute;
        font-family: "Montserrat", sans-serif;
        font-size: 100px;
        font-weight: 500;
        top: -30px;
        transform: translateY(-25%); }
      .section-2__bt-main__up-list--item p {
        font-size: 3rem;
        text-align: center;
        line-height: 4rem;
        font-weight: bold;
        margin-bottom: 25px;
        margin-top: 25px; }
      .section-2__bt-main__up-list--item--img {
        width: 206px; }

.section-3 {
  width: 100%;
  background-color: #fff;
  background-image: url("../assets/images/section-3-bg-top.png");
  background-position: top;
  background-repeat: no-repeat;
  padding-top: 130px;
  overflow: hidden; }
  @media (min-width: 1676px) {
    .section-3 {
      padding-top: 157px;
      background-size: 100%; } }
  .section-3--title-img {
    position: absolute;
    top: -125px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10; }
    @media (max-width: 1000.98px) {
      .section-3--title-img {
        width: 90px;
        top: -110px; } }
  .section-3__box-1 {
    position: relative;
    background-color: #57D2FF;
    margin-bottom: -130px; }
    @media (max-width: 1000.98px) {
      .section-3__box-1__content__top {
        padding-top: 10px; } }
    .section-3__box-1__content__top p {
      font-family: "Hiragino Kaku Gothic ProN", "源ノ角ゴシック JP", "メイリオ", "Meiryo", sans-serif; }
      .section-3__box-1__content__top p:nth-child(2) {
        font-size: 4.9rem;
        font-weight: 500;
        line-height: 6rem;
        margin: 30px 0px; }
        @media (max-width: 1000.98px) {
          .section-3__box-1__content__top p:nth-child(2) {
            font-size: 3.9rem;
            margin: 20px 0px; } }
        @media (max-width: 434.98px) {
          .section-3__box-1__content__top p:nth-child(2) {
            font-size: 3.5rem;
            line-height: 5rem; } }
    .section-3__box-1__content__bottom {
      display: flex;
      position: relative;
      height: 800px; }
      @media (max-width: 1000.98px) {
        .section-3__box-1__content__bottom {
          flex-direction: column;
          height: 950px; } }
      @media (max-width: 434.98px) {
        .section-3__box-1__content__bottom {
          height: 1170px; } }
      @media (max-width: 350px) {
        .section-3__box-1__content__bottom {
          height: 1250px; } }
      .section-3__box-1__content__bottom--1 {
        z-index: 4; }
        .section-3__box-1__content__bottom--1 p.t-1 {
          font-size: 7rem;
          font-weight: 400;
          padding: 3px;
          line-height: 1.2;
          margin-bottom: 25px;
          background-color: #FAE12E;
          display: inline-block; }
          @media (max-width: 1000.98px) {
            .section-3__box-1__content__bottom--1 p.t-1 {
              padding: 8px;
              font-size: 5rem;
              margin-bottom: 20px;
              line-height: 1; } }
        .section-3__box-1__content__bottom--1 p.t-2 {
          font-size: 4rem;
          font-weight: 400;
          line-height: 3.2rem;
          margin-bottom: 35px; }
          @media (max-width: 1000.98px) {
            .section-3__box-1__content__bottom--1 p.t-2 {
              font-size: 2.8rem;
              line-height: 30px; } }
        .section-3__box-1__content__bottom--1 p.t-3 {
          width: 50%;
          padding: 35px 40px;
          font-family: "Hiragino Kaku Gothic ProN", "源ノ角ゴシック JP", "メイリオ", "Meiryo", sans-serif;
          background-color: #fff;
          font-size: 18px;
          font-weight: 300;
          line-height: 3.4rem;
          text-align: justify; }
          @media (max-width: 1000.98px) {
            .section-3__box-1__content__bottom--1 p.t-3 {
              font-size: 16px;
              height: auto;
              width: 100%;
              margin-top: 400px; } }
      .section-3__box-1__content__bottom img {
        margin-top: 40px;
        position: absolute;
        right: 0;
        width: 560px;
        height: 560px;
        z-index: 1; }
        @media (max-width: 1000.98px) {
          .section-3__box-1__content__bottom img {
            right: -15%;
            width: 480px;
            height: 480px;
            top: 135px; } }
  .section-3__box-2 {
    position: relative;
    width: 100%;
    background-color: #FAE12E;
    clip-path: polygon(100% 100%, 100% 0%, 0% 100px, 0% 100%);
    padding-top: 260px;
    padding-bottom: 200px;
    margin-bottom: -140px;
    z-index: 0;
    z-index: 0; }
    @media (min-width: 1676px) {
      .section-3__box-2 {
        margin-bottom: -160px; } }
    @media (max-width: 1000.98px) {
      .section-3__box-2 {
        padding-bottom: 150px;
        padding-top: 170px;
        clip-path: polygon(100% 100%, 100% 0%, 0% 45px, 0% 100%); } }
    @media (max-width: 1000.98px) {
      .section-3__box-2__content {
        padding-top: 50px; } }
    .section-3__box-2 .section-3--title-img {
      top: 90px; }
    .section-3__box-2__description p {
      font-family: "Hiragino Kaku Gothic ProN", "源ノ角ゴシック JP", "メイリオ", "Meiryo", sans-serif;
      line-height: 6rem; }
      @media (max-width: 1000.98px) {
        .section-3__box-2__description p {
          line-height: 5rem; } }
      .section-3__box-2__description p.t-1 {
        font-size: 5rem;
        margin-top: 20px; }
        @media (max-width: 1000.98px) {
          .section-3__box-2__description p.t-1 {
            font-size: 3.9rem; } }
        @media (max-width: 434.98px) {
          .section-3__box-2__description p.t-1 {
            font-size: 3.5rem; } }
      .section-3__box-2__description p.t-2 {
        font-size: 4rem; }
        @media (max-width: 1000.98px) {
          .section-3__box-2__description p.t-2 {
            font-size: 21px;
            line-height: 30px;
            margin-top: 15px; } }
    .section-3__box-2__main {
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 600px;
      margin-top: 10px; }
      @media (max-width: 1000.98px) {
        .section-3__box-2__main {
          flex-direction: column;
          height: 755px; } }
      .section-3__box-2__main--absolute {
        position: absolute;
        font-size: 7rem;
        font-family: "Hiragino Kaku Gothic ProN", "源ノ角ゴシック JP", "メイリオ", "Meiryo", sans-serif;
        display: inline-block;
        background-color: #57D2FF;
        z-index: 9;
        line-height: 7rem;
        left: 0;
        top: 0; }
        @media (max-width: 1000.98px) {
          .section-3__box-2__main--absolute {
            font-size: 5rem; } }
      .section-3__box-2__main--img {
        position: absolute;
        width: 560px;
        height: 560px;
        z-index: 1;
        left: 0;
        top: 40px; }
        @media (max-width: 1000.98px) {
          .section-3__box-2__main--img {
            left: -15%;
            width: 480px;
            height: 480px; } }
      .section-3__box-2__main--p {
        width: 484px;
        padding: 35px 40px;
        font-family: "Hiragino Kaku Gothic ProN", "源ノ角ゴシック JP", "メイリオ", "Meiryo", sans-serif;
        background-color: #fff;
        font-size: 18px;
        font-weight: 300;
        line-height: 3.4rem;
        justify-self: flex-end;
        z-index: 3;
        text-align: justify; }
        @media (max-width: 1000.98px) {
          .section-3__box-2__main--p {
            font-size: 16px;
            height: auto;
            width: 100%; } }
    .section-3__box-2__checklist {
      width: 100%;
      padding: 0 50px;
      margin-top: -22px; }
      @media (max-width: 1000.98px) {
        .section-3__box-2__checklist {
          margin-top: 20px;
          padding: 0; } }
      .section-3__box-2__checklist--main {
        width: 100%;
        background-color: #fff;
        border: 20px solid #57D2FF;
        padding: 60px 90px; }
        @media (max-width: 1000.98px) {
          .section-3__box-2__checklist--main {
            padding: 45px 30px; } }
        @media (max-width: 434.98px) {
          .section-3__box-2__checklist--main {
            padding: 30px; } }
        @media (max-width: 340px) {
          .section-3__box-2__checklist--main {
            padding: 30px 15px; } }
        .section-3__box-2__checklist--main--title {
          font-size: 3.6rem;
          font-family: "Hiragino Kaku Gothic ProN", "源ノ角ゴシック JP", "メイリオ", "Meiryo", sans-serif;
          line-height: 6rem;
          position: relative;
          margin-bottom: 80px;
          text-align: center; }
          @media (max-width: 1000.98px) {
            .section-3__box-2__checklist--main--title {
              font-size: 2.8rem;
              line-height: 3.5rem;
              margin-bottom: 45px; } }
          @media (max-width: 434.98px) {
            .section-3__box-2__checklist--main--title {
              font-size: 16px;
              line-height: 24px; } }
          .section-3__box-2__checklist--main--title::after {
            content: "";
            width: 80px;
            height: 10px;
            background-color: #FAE12E;
            position: absolute;
            top: 70px;
            left: 50%;
            transform: translateX(-50%); }
            @media (max-width: 434.98px) {
              .section-3__box-2__checklist--main--title::after {
                top: 53px; } }
        .section-3__box-2__checklist--main .main-content:not(:last-child) {
          margin-bottom: 50px; }
        .section-3__box-2__checklist--main .main-content--title {
          font-family: "Montserrat", sans-serif;
          font-size: 4rem;
          text-transform: uppercase;
          letter-spacing: 2px;
          margin-bottom: 20px; }
        .section-3__box-2__checklist--main .main-content .check-list {
          display: flex; }
          @media (max-width: 1000.98px) {
            .section-3__box-2__checklist--main .main-content .check-list {
              display: block; } }
          .section-3__box-2__checklist--main .main-content .check-list--1 {
            width: 50%; }
            @media (max-width: 1000.98px) {
              .section-3__box-2__checklist--main .main-content .check-list--1 {
                width: 100%; } }
            .section-3__box-2__checklist--main .main-content .check-list--1 p {
              font-size: 16px;
              display: flex;
              align-items: center;
              letter-spacing: 2px;
              line-height: 40px; }
              @media (max-width: 434.98px) {
                .section-3__box-2__checklist--main .main-content .check-list--1 p {
                  font-size: 14px;
                  line-height: 30px;
                  letter-spacing: 0; } }
              .section-3__box-2__checklist--main .main-content .check-list--1 p img {
                margin-right: 10px; }
                @media (max-width: 434.98px) {
                  .section-3__box-2__checklist--main .main-content .check-list--1 p img {
                    margin-right: 5px;
                    width: 18px;
                    height: 18px; } }
  .section-3__box-3-wrapper {
    padding-top: 137px;
    background-image: url("../assets/images/section-3-bg-2.png");
    background-position: top;
    background-repeat: no-repeat;
    position: relative;
    z-index: 3; }
    @media (min-width: 1676px) {
      .section-3__box-3-wrapper {
        padding-top: 157px;
        background-size: 100%; } }
  .section-3__box-3 {
    margin-bottom: -100px;
    position: relative;
    width: 100%;
    background-color: #57D2FD; }

.clip-path {
  clip-path: polygon(100% 100%, 100% 100px, 0% 0%, 0% 100%);
  position: relative;
  z-index: 4;
  padding-top: 160px !important; }
  @media (max-width: 1000.98px) {
    .clip-path {
      clip-path: polygon(100% 100%, 100% 40px, 0% 0%, 0% 100%);
      padding-top: 95px !important; } }

@media (max-width: 1000.98px) {
  .section-3__box-3-wrapper .section-3__box-1__content__bottom {
    height: 1145px; } }

@media (max-width: 434.98px) {
  .section-3__box-3-wrapper .section-3__box-1__content__bottom {
    height: 1290px; } }

.section-4 {
  background-color: #D8E2EB;
  padding-bottom: 120px; }
  .section-4-part-1 {
    background-color: #D8E2EB; }
    .section-4-part-1-title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 80px;
      margin-top: 120px; }
      @media (max-width: 1099px) {
        .section-4-part-1-title {
          margin-top: 60px; } }
      @media (max-width: 1000.98px) {
        .section-4-part-1-title img {
          width: 310px; } }
      @media (max-width: 434.98px) {
        .section-4-part-1-title img {
          width: 230px; } }
      .section-4-part-1-title .span-1 {
        font-size: 40px;
        margin-left: 10px;
        font-family: "Noto Sans JP", sans-serif;
        font-weight: bold;
        padding-top: 20px; }
        @media (max-width: 1000.98px) {
          .section-4-part-1-title .span-1 {
            font-size: 23px;
            padding-top: 20px; } }
      .section-4-part-1-title .span-2 {
        margin-top: 32px;
        font-family: "Noto Sans JP", sans-serif;
        font-weight: bold;
        font-size: 50px;
        line-height: 13.1rem; }
        @media (max-width: 1000.98px) {
          .section-4-part-1-title .span-2 {
            font-size: 32px; } }
        @media (max-width: 1000.98px) {
          .section-4-part-1-title .span-2 {
            font-size: 22px; } }
    .section-4-part-1-content {
      position: relative;
      background-color: #ffffff;
      border-radius: 50px;
      margin-top: 150px; }
      @media (max-width: 1000.98px) {
        .section-4-part-1-content {
          border-radius: 0; } }
      .section-4-part-1-content__header {
        z-index: 9999;
        position: relative;
        top: -150px;
        background-color: #FAE12E;
        width: 920px;
        border-bottom-right-radius: 60px;
        border-top-right-radius: 60px; }
        @media (max-width: 1000.98px) {
          .section-4-part-1-content__header {
            width: 100%;
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px; } }
        .section-4-part-1-content__header-point {
          position: absolute;
          margin-top: -100px;
          margin-left: 24px;
          background-color: #FAE12E;
          width: 175px;
          border-radius: 190px;
          height: 30px; }
          @media (max-width: 1000.98px) {
            .section-4-part-1-content__header-point {
              margin-top: -55px;
              margin-left: 70px;
              width: 150px; } }
          @media (max-width: 434.98px) {
            .section-4-part-1-content__header-point {
              margin-left: 30px; } }
          .section-4-part-1-content__header-point span {
            font-family: "Montserrat", sans-serif; }
            .section-4-part-1-content__header-point span:nth-child(1) {
              position: absolute;
              color: #ffffff;
              font-size: 28px;
              font-weight: bold;
              line-height: 3.7rem;
              margin-top: -3px;
              margin-left: 18px; }
              @media (max-width: 1000.98px) {
                .section-4-part-1-content__header-point span:nth-child(1) {
                  font-size: 24px;
                  left: 12px;
                  bottom: 0;
                  margin-left: 5px; } }
            .section-4-part-1-content__header-point span:nth-child(2) {
              position: absolute;
              color: #000000;
              font-size: 52px;
              font-weight: bold;
              line-height: 6.8rem;
              margin-top: -25px;
              margin-left: 98px;
              font-family: "Roboto", sans-serif;
              font-weight: 500; }
              @media (max-width: 1000.98px) {
                .section-4-part-1-content__header-point span:nth-child(2) {
                  font-size: 38px;
                  margin-top: -11px;
                  margin-left: 90px; } }
        .section-4-part-1-content__header img {
          padding-right: 10px;
          width: 920px;
          margin-bottom: 0px;
          padding-bottom: 0px; }
          @media (max-width: 1000.98px) {
            .section-4-part-1-content__header img {
              width: 100%;
              padding-right: 0; } }
        .section-4-part-1-content__header-text {
          margin-left: 80px; }
          @media (max-width: 1000.98px) {
            .section-4-part-1-content__header-text {
              padding: 24px 0;
              margin-left: 70px; } }
          @media (max-width: 434.98px) {
            .section-4-part-1-content__header-text {
              padding: 20px 30px;
              margin-left: 0; } }
          .section-4-part-1-content__header-text span {
            line-height: 8rem; }
            @media (max-width: 1000.98px) {
              .section-4-part-1-content__header-text span {
                line-height: 6rem; } }
            .section-4-part-1-content__header-text span:nth-child(1) {
              font-family: "Noto Sans JP", sans-serif;
              font-weight: bold;
              font-size: 4rem; }
              @media (max-width: 1000.98px) {
                .section-4-part-1-content__header-text span:nth-child(1) {
                  margin-bottom: 8px;
                  line-height: 1;
                  display: block;
                  font-size: 2.8rem; } }
            .section-4-part-1-content__header-text span:nth-child(2) {
              font-family: "Noto Sans JP", sans-serif;
              font-weight: bold;
              font-size: 6rem; }
              @media (max-width: 1000.98px) {
                .section-4-part-1-content__header-text span:nth-child(2) {
                  display: block;
                  line-height: 1;
                  font-size: 4.2rem; } }
      .section-4-part-1-content__body {
        background-color: #ffffff;
        padding: 50px 80px 80px 80px;
        border-radius: 50px;
        margin-top: -150px; }
        @media (max-width: 1000.98px) {
          .section-4-part-1-content__body {
            padding: 40px 0px 0px 0px; } }
        .section-4-part-1-content__body-wrapper .row {
          padding-bottom: 20px; }
          @media (max-width: 1000.98px) {
            .section-4-part-1-content__body-wrapper .row {
              padding-bottom: 0; } }
        .section-4-part-1-content__body-make-line {
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;
          margin-left: 120px; }
          .section-4-part-1-content__body-make-line img {
            width: 258px;
            height: 38px; }
          .section-4-part-1-content__body-make-line .line {
            width: 70px;
            height: 7px;
            background-color: #FAE12E;
            margin-top: 5px; }
        .section-4-part-1-content__body-title {
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;
          font-size: 30px;
          font-family: "Noto Sans JP", sans-serif;
          font-weight: 500;
          margin-left: 100px; }
          .section-4-part-1-content__body-title .line {
            width: 70px;
            height: 7px;
            background-color: #FAE12E;
            margin-top: 5px; }
  .section-4-part-2-content {
    margin-top: 250px; }
    @media (max-width: 1000.98px) {
      .section-4-part-2-content {
        margin-top: 150px; } }
    .section-4-part-2-content__header {
      border-bottom-right-radius: unset;
      border-top-right-radius: unset;
      border-top-left-radius: 60px;
      border-bottom-left-radius: 60px;
      padding-left: 10px;
      right: -80px; }
      @media (max-width: 1000.98px) {
        .section-4-part-2-content__header {
          right: 0px;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          padding-left: 0px; } }
      .section-4-part-2-content__header-point {
        top: 280px;
        left: -60px;
        margin-top: unset; }
        @media (max-width: 1000.98px) {
          .section-4-part-2-content__header-point {
            margin-top: -55px;
            margin-left: 70px;
            top: auto;
            left: auto; } }
        @media (max-width: 434.98px) {
          .section-4-part-2-content__header-point {
            margin-left: 30px; } }
      .section-4-part-2-content__header-text {
        margin-right: 80px;
        text-align: right; }
        @media (max-width: 1000.98px) {
          .section-4-part-2-content__header-text {
            margin-left: 70px;
            text-align: unset; } }
        @media (max-width: 434.98px) {
          .section-4-part-2-content__header-text {
            margin-left: unset;
            margin-right: unset; } }
    @media (max-width: 1000.98px) {
      .section-4-part-2-content__body {
        padding-bottom: 80px; } }
    .section-4-part-2-content__body .content-top {
      display: flex;
      position: relative; }
      @media (max-width: 1000.98px) {
        .section-4-part-2-content__body .content-top {
          flex-direction: column; } }
      .section-4-part-2-content__body .content-top--line {
        width: 162px;
        height: 45px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin: 20px 0; }
      .section-4-part-2-content__body .content-top--absolute {
        position: absolute;
        left: 0;
        top: 215px;
        width: 100%; }
      .section-4-part-2-content__body .content-top__box {
        padding-bottom: 15px; }
        @media (max-width: 1000.98px) {
          .section-4-part-2-content__body .content-top__box {
            display: flex;
            width: 100% !important; } }
        .section-4-part-2-content__body .content-top__box:not(:last-child) {
          background-image: linear-gradient(#707070 60%, rgba(255, 255, 255, 0) 0%);
          background-position: right;
          background-size: 1px 10px;
          background-repeat: repeat-y; }
          @media (max-width: 1000.98px) {
            .section-4-part-2-content__body .content-top__box:not(:last-child) {
              background-image: none; } }
        .section-4-part-2-content__body .content-top__box--des {
          margin-top: 85px; }
          @media (max-width: 1000.98px) {
            .section-4-part-2-content__body .content-top__box--des {
              margin-top: 0;
              margin-left: 18px; } }
          .section-4-part-2-content__body .content-top__box--des p {
            font-size: 16px;
            font-weight: 400;
            padding-left: 20px;
            position: relative;
            text-align: justify; }
            .section-4-part-2-content__body .content-top__box--des p:not(:last-child) {
              margin-bottom: 14px; }
            .section-4-part-2-content__body .content-top__box--des p::before {
              content: "";
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background-color: #FAE12E;
              position: absolute;
              left: 0;
              top: 2px; }
        .section-4-part-2-content__body .content-top__box--imgs {
          display: flex; }
          .section-4-part-2-content__body .content-top__box--imgs :not(:last-child) {
            margin-right: 18px; }
            @media (max-width: 1000.98px) {
              .section-4-part-2-content__body .content-top__box--imgs :not(:last-child) {
                margin-right: 0; } }
          @media (max-width: 1000.98px) {
            .section-4-part-2-content__body .content-top__box--imgs {
              flex-direction: column;
              margin-right: 0; } }
          @media (max-width: 1000.98px) {
            .section-4-part-2-content__body .content-top__box--imgs img {
              width: 180px; } }
        .section-4-part-2-content__body .content-top__box--1 {
          width: 200px;
          padding-right: 18px; }
          @media (max-width: 1000.98px) {
            .section-4-part-2-content__body .content-top__box--1 {
              padding-right: 0; } }
        .section-4-part-2-content__body .content-top__box--2 {
          flex: 1;
          padding-right: 18px;
          padding-left: 18px; }
          @media (max-width: 1000.98px) {
            .section-4-part-2-content__body .content-top__box--2 {
              padding-right: 0;
              padding-left: 0; } }
        .section-4-part-2-content__body .content-top__box--3 {
          padding-left: 18px;
          width: 200px; }
          @media (max-width: 1000.98px) {
            .section-4-part-2-content__body .content-top__box--3 {
              padding-left: 0; } }
    .section-4-part-2-content__body .content-bottom {
      margin-top: 60px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 40px; }
      @media (max-width: 1000.98px) {
        .section-4-part-2-content__body .content-bottom {
          display: flex;
          flex-direction: column;
          padding: 0 5%;
          gap: 0; } }
      @media (max-width: 1000.98px) {
        .section-4-part-2-content__body .content-bottom img {
          max-width: 100%; }
          .section-4-part-2-content__body .content-bottom img:not(:last-child) {
            margin-bottom: 20px; } }
  .section-4-part-3 {
    margin-top: 110px; }
    @media (max-width: 1000.98px) {
      .section-4-part-3 {
        margin-top: 0; } }
    .section-4-part-3-content__body-row {
      padding-bottom: 45px; }
      .section-4-part-3-content__body-row-title {
        font-size: 30px;
        font-weight: bold;
        line-height: 4.5rem;
        background-color: #95E3FF;
        text-align: justify; }
        @media (max-width: 1000.98px) {
          .section-4-part-3-content__body-row-title {
            font-size: 2.6rem;
            line-height: 28px;
            padding-right: 5px;
            padding-left: 5px; } }
        @media (max-width: 434.98px) {
          .section-4-part-3-content__body-row-title {
            font-size: 2.3rem; } }
      .section-4-part-3-content__body-row-content {
        padding-top: 10px;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: justify; }
        @media (max-width: 434.98px) {
          .section-4-part-3-content__body-row-content {
            font-size: 16px; } }

.hifu {
  width: 320px;
  border-radius: 15px;
  font-size: 16px;
  font-family: "Noto Sans JP", sans-serif;
  background-color: #CFEBF5;
  font-weight: 22px;
  padding: 30px 20px;
  display: flex;
  align-items: center; }
  @media (max-width: 1000.98px) {
    .hifu {
      display: block;
      padding: 0px 40px 25px 23px; } }
  @media (max-width: 434.98px) {
    .hifu {
      padding: 0px 15px 25px 23px; } }
  @media (max-width: 1000.98px) {
    .hifu .p-hifu {
      font-size: 22px;
      font-weight: bold;
      text-align: center; } }
  .hifu p {
    line-height: 23px; }
    @media (max-width: 1000.98px) {
      .hifu p {
        padding-top: 22px; } }
  .hifu p:not(.p-hifu) {
    font-weight: 400; }
    @media (max-width: 1000.98px) {
      .hifu p:not(.p-hifu) {
        padding-top: 10px; } }
  .hifu-4 {
    display: block;
    padding-top: 25px;
    padding-bottom: 25px; }
    @media (max-width: 1000.98px) {
      .hifu-4 {
        padding-top: 0; } }
    .hifu-4 .flex-box {
      display: flex; }
    .hifu-4 img {
      margin-top: 10px;
      max-width: 100%; }

.arrow-1, .arrow-2, .arrow-3, .arrow-4 {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .arrow-1 svg, .arrow-2 svg, .arrow-3 svg, .arrow-4 svg {
    width: 48px;
    height: 48px; }
    @media (max-width: 1000.98px) {
      .arrow-1 svg, .arrow-2 svg, .arrow-3 svg, .arrow-4 svg {
        transform: rotate(90deg); } }

@media (max-width: 1000.98px) {
  .make-line {
    position: relative;
    padding: 68px 15px 0px 23px;
    margin-bottom: 65px; } }

@media (max-width: 420px) {
  .make-line {
    flex-direction: column;
    justify-content: center;
    align-items: center; } }

.make-line .make-line-img {
  position: absolute;
  width: 214px;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  top: 25px;
  margin: 0 !important; }

.make-line-1 {
  border-radius: 15px;
  width: 443px;
  height: 122px;
  background-color: #9BE4FF;
  display: flex; }
  @media (max-width: 1000.98px) {
    .make-line-1 {
      height: auto; } }
  @media (max-width: 420px) {
    .make-line-1 {
      padding-bottom: 10px; } }
  .make-line-1 p {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    padding: 22px;
    text-align: justify; }
    @media (max-width: 434.98px) {
      .make-line-1 p {
        font-size: 16px; } }
    @media (max-width: 420px) {
      .make-line-1 p {
        padding: 0px 0px 0 0; } }
  .make-line-1 img {
    width: 152px;
    height: 152px;
    margin-top: -15px; }
    @media (max-width: 1000.98px) {
      .make-line-1 img {
        margin-top: 0;
        margin-bottom: -15px; } }
    @media (max-width: 420px) {
      .make-line-1 img {
        margin-top: 10px;
        margin-bottom: 0px; } }

.make-line-2 {
  border-radius: 15px;
  background-color: #9BE4FF;
  padding-left: 20px;
  padding-top: 25px;
  padding-bottom: 28px;
  padding-right: 12px;
  display: inline-flex; }
  @media (max-width: 1000.98px) {
    .make-line-2 {
      padding-top: 68px;
      padding-right: 15px;
      padding-bottom: 10px;
      padding-left: 23px; } }
  .make-line-2 p {
    width: 250px;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    padding-right: 17px;
    text-align: justify; }
    @media (max-width: 1000.98px) {
      .make-line-2 p {
        width: 100%; } }
    @media (max-width: 434.98px) {
      .make-line-2 p {
        font-size: 16px;
        padding-right: 0; } }
  .make-line-2 img {
    width: 152px;
    height: 152px;
    margin-top: -15px; }
    @media (max-width: 420px) {
      .make-line-2 img {
        margin-top: 10px; } }

.make-line-4 {
  border-radius: 15px;
  width: 443px;
  background-color: #9BE4FF;
  padding-top: 25px;
  padding-bottom: 25px; }
  @media (max-width: 1000.98px) {
    .make-line-4 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 68px;
      padding-bottom: 25px;
      padding-left: 23px; } }
  .make-line-4 p {
    padding-left: 25px;
    font-family: "Noto Sans JP", sans-serif;
    font-size: 18px;
    line-height: 24px;
    padding-right: 20px;
    text-align: justify; }
    @media (max-width: 1000.98px) {
      .make-line-4 p {
        padding-left: 0;
        padding-right: 0; } }
    @media (max-width: 434.98px) {
      .make-line-4 p {
        font-size: 16px; } }
  .make-line-4 img {
    margin-top: 10px;
    width: 383px;
    height: 228px;
    margin: 15px; }
    @media (max-width: 1000.98px) {
      .make-line-4 img {
        max-width: 100%;
        height: auto; } }

.underline {
  display: inline;
  border-bottom: 3px solid #FAE12E; }

.section-4-2__title {
  font-size: 3.2rem;
  font-weight: 600;
  background-color: #FAE12E;
  width: fit-content;
  padding: 8px 16px;
  margin-bottom: 24px;
  text-align: justify;
  line-height: 1.4; }

.section-4-2__text {
  font-size: 18px;
  text-align: justify;
  margin-bottom: 40px; }
  @media (max-width: 1000.98px) {
    .section-4-2__text {
      font-size: 16px; } }

.section-4-2__img {
  width: 100%; }
  .section-4-2__img img {
    width: 100%; }

.section-5 {
  width: 100%;
  background-color: #FCF9ED;
  padding: 120px 0 80px 0; }
  @media (max-width: 1000.98px) {
    .section-5 {
      padding: 50px 0 40px 0; } }
  .section-5--main-img {
    max-width: 100%; }
    @media (max-width: 1000.98px) {
      .section-5--main-img {
        height: 63px; } }
    @media (max-width: 1000.98px) {
      .section-5--main-img {
        height: 47px; } }
  .section-5--title {
    font-family: "Hiragino Kaku Gothic ProN", "源ノ角ゴシック JP", "メイリオ", "Meiryo", sans-serif;
    font-size: 4.2rem;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 3px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 24px; }
    @media (max-width: 1000.98px) {
      .section-5--title {
        font-size: 22px;
        margin-top: 24px; } }
  .section-5 .logo-title {
    color: #000;
    font-size: 2.4rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500; }
  .section-5--beauty-lala {
    margin-bottom: 24px;
    width: 40%; }
    @media (max-width: 1000.98px) {
      .section-5--beauty-lala {
        width: 100%;
        max-width: 240px; } }
  .section-5--description {
    margin-bottom: 32px;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    max-width: 840px;
    text-align: justify; }
    @media (max-width: 1000px) {
      .section-5--description {
        width: 100%;
        font-size: 16px; } }
  .section-5__logo-list {
    width: 100%;
    max-width: 840px; }
    .section-5__logo-list:first-child {
      margin-bottom: 24px; }
      @media (max-width: 1000.98px) {
        .section-5__logo-list:first-child {
          margin-bottom: 40px; } }
    .section-5__logo-list__description {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      padding-left: 24px;
      height: 40px;
      font-size: 24px;
      font-weight: 700; }
      @media (max-width: 1000.98px) {
        .section-5__logo-list__description {
          margin-bottom: 24px;
          padding-left: 16px;
          height: 32px; } }
      .section-5__logo-list__description.red {
        border-left: solid 6px #DB5C51;
        color: #DB5C51; }
      .section-5__logo-list__description.blue {
        border-left: solid 6px #3E86C9;
        color: #3E86C9; }
    .section-5__logo-list__item {
      display: flex;
      gap: 2%; }
      @media (max-width: 1000.98px) {
        .section-5__logo-list__item {
          flex-direction: column;
          gap: 24px; } }
      .section-5__logo-list__item div {
        width: 32%; }
        @media (max-width: 1000.98px) {
          .section-5__logo-list__item div {
            width: 100%; } }
      .section-5__logo-list__item img {
        display: flex;
        width: 100%;
        height: auto; }
      .section-5__logo-list__item a {
        display: inline-block;
        margin-top: 8px;
        color: #000;
        font-size: 14px;
        line-height: 1.4;
        text-decoration: none; }
        .section-5__logo-list__item a:hover {
          color: #DB5C51; }
        .section-5__logo-list__item a span {
          font-size: 18px; }
  .section-5__youtube-video {
    width: 100%; }
    .section-5__youtube-video iframe {
      display: flex;
      width: 100%;
      aspect-ratio: 16/9; }

h3.video-title {
  margin-top: 80px;
  padding: 16px 0;
  background-color: #FAE12E;
  width: 100%;
  text-align: center;
  margin-top: 80px;
  font-size: 24px; }
  @media (max-width: 1000.98px) {
    h3.video-title {
      margin-top: 40px; } }

.section-6 {
  width: 100%;
  background-color: #fff; }
  .section-6__top-banner {
    width: 100%;
    height: 158px;
    background-color: #FAE12E;
    display: flex;
    align-items: center; }
    @media (max-width: 1000.98px) {
      .section-6__top-banner {
        height: 96px; } }
    .section-6__top-banner__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative; }
      @media (max-width: 1000.98px) {
        .section-6__top-banner__content {
          justify-content: center; } }
      .section-6__top-banner__content p {
        font-size: 6rem;
        font-weight: bold;
        font-family: "Hiragino Kaku Gothic ProN", "源ノ角ゴシック JP", "メイリオ", "Meiryo", sans-serif;
        line-height: 7rem; }
        @media (max-width: 1000.98px) {
          .section-6__top-banner__content p {
            font-size: 4rem; } }
      .section-6__top-banner__content::after {
        content: "";
        position: absolute;
        border-top: 80px solid #FAE12E;
        border-left: 120px solid transparent;
        border-right: 120px solid transparent;
        bottom: -90px;
        left: 50%;
        transform: translateX(-50%); }
        @media (max-width: 1000.98px) {
          .section-6__top-banner__content::after {
            border-top: 40px solid #FAE12E;
            border-left: 80px solid transparent;
            border-right: 80px solid transparent;
            bottom: -50px; } }
  .section-6__main {
    padding-top: 110px;
    padding-bottom: 110px; }
    @media (max-width: 1000.98px) {
      .section-6__main {
        padding-top: 80px; } }
    .section-6__main--item {
      border: 4px solid #D8E2EB;
      box-shadow: 10px 10px 0px #D8E2EB;
      padding: 70px 70px 40px 70px;
      position: relative; }
      @media (max-width: 1000.98px) {
        .section-6__main--item {
          padding: 40px 5% 40px 5%; } }
      .section-6__main--item:not(:last-child) {
        margin-bottom: 80px; }
      .section-6__main--item--flag {
        position: absolute;
        font-family: "Montserrat", sans-serif;
        font-weight: 200;
        font-size: 4rem;
        top: -40px;
        background-color: #fff;
        padding: 0 10px 0 30px;
        display: flex;
        align-items: center;
        text-align: justify; }
        .section-6__main--item--flag span {
          font-size: 7.4rem;
          font-family: "Montserrat", sans-serif;
          line-height: 8rem;
          margin-left: 15px; }
        @media (max-width: 1000.98px) {
          .section-6__main--item--flag {
            padding: 0 10px 0 0px; } }
      .section-6__main--item--title {
        font-size: 4.2rem;
        font-weight: bold;
        font-family: "Hiragino Kaku Gothic ProN", "源ノ角ゴシック JP", "メイリオ", "Meiryo", sans-serif;
        margin-bottom: 30px; }
        @media (max-width: 1000.98px) {
          .section-6__main--item--title {
            font-size: 2.4rem;
            margin-bottom: 10px; } }
      .section-6__main--item--sub {
        font-size: 18px;
        text-align: justify; }
        @media (max-width: 1000.98px) {
          .section-6__main--item--sub {
            font-size: 16px; } }
      .section-6__main--item__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px; }
        @media (max-width: 1000.98px) {
          .section-6__main--item__content {
            flex-direction: column; } }
        .section-6__main--item__content--1 {
          background-color: #FAE12E;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 386px;
          height: 80px;
          border-radius: 22px; }
          @media (max-width: 1000.98px) {
            .section-6__main--item__content--1 {
              width: 100%;
              margin-bottom: 10px; } }
          .section-6__main--item__content--1 img {
            max-width: 100%; }
        .section-6__main--item__content--2 {
          width: 44px;
          height: 44px; }
        .section-6__main--item__content--3 {
          font-size: 2.6rem;
          font-weight: bold;
          background-color: #FAE12E;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 386px;
          height: 80px;
          border-radius: 22px; }
          @media (max-width: 1000.98px) {
            .section-6__main--item__content--3 {
              width: 100%;
              margin-top: 10px; } }
          @media (max-width: 434.98px) {
            .section-6__main--item__content--3 {
              font-size: 3rem; } }
  .section-6__line {
    width: 100%;
    height: 10px;
    background-color: #FAE12E; }
  .section-6__performances {
    padding: 130px 0 120px 0; }
    @media (max-width: 1000.98px) {
      .section-6__performances {
        padding: 80px 0 120px 0; } }
    .section-6__performances__list {
      width: 100%;
      max-height: 300px;
      overflow: auto;
      margin-top: 40px; }
      @media (max-width: 1000.98px) {
        .section-6__performances__list {
          max-height: 600px; } }
    .section-6__performances--item {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #333333;
      padding: 17px 0;
      color: inherit; }
      @media (max-width: 1000.98px) {
        .section-6__performances--item {
          flex-direction: column; } }
      .section-6__performances--item:last-child {
        border-bottom: 1px solid #333333; }
      .section-6__performances--item p {
        line-height: 2.4rem; }
      .section-6__performances--item--title {
        flex-basis: 300px;
        font-size: 18px;
        font-weight: bold; }
        @media (max-width: 1000.98px) {
          .section-6__performances--item--title {
            flex-basis: auto;
            margin-bottom: 15px; } }
      .section-6__performances--item--address {
        flex-basis: 222px;
        font-size: 16px;
        font-weight: 400; }
        @media (max-width: 1000.98px) {
          .section-6__performances--item--address {
            flex-basis: auto;
            margin-bottom: 15px; } }
      .section-6__performances--item--tel {
        font-size: 16px;
        font-weight: 400; }
        @media (max-width: 1000.98px) {
          .section-6__performances--item--tel {
            margin-bottom: 8px; } }
      .section-6__performances--item--btn {
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        color: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #57D2FF;
        width: 130px;
        height: 30px;
        text-align: center;
        border-radius: 15px; }

.section-7 {
  background-color: #F5F5F4;
  padding: 120px 0;
  position: relative;
  z-index: 0; }
  @media (max-width: 1000.98px) {
    .section-7 {
      padding: 50px 0; } }
  .section-7::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 800px;
    background-color: #FAE12E;
    bottom: 0;
    z-index: -1;
    clip-path: polygon(100% 0%, 100% 100%, 0% 100%); }
  .section-7 .process-wrapper {
    margin-top: 65px; }
    @media (max-width: 1000.98px) {
      .section-7 .process-wrapper {
        margin-top: 40px; } }
  .section-7 .process {
    width: 100%;
    height: 189px;
    background-color: #fff;
    border-radius: 20px;
    position: relative;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16); }
    @media (max-width: 1000.98px) {
      .section-7 .process {
        padding: 40px 30px 25px 30px;
        height: auto; } }
    .section-7 .process:not(:last-child) {
      margin-bottom: 60px; }
      @media (max-width: 1000.98px) {
        .section-7 .process:not(:last-child) {
          margin-bottom: 30px; } }
    .section-7 .process--flag {
      position: absolute;
      background-color: #FAE12E;
      width: 95px;
      transform: skew(-20deg);
      left: 20px;
      top: -10px; }
      @media (max-width: 1000.98px) {
        .section-7 .process--flag {
          width: 80px; } }
      .section-7 .process--flag p {
        font-family: "Montserrat", sans-serif;
        font-size: 1.6rem;
        color: #fff;
        text-transform: uppercase;
        transform: skew(20deg);
        padding-left: 17px; }
        .section-7 .process--flag p span {
          font-size: 3.2rem;
          color: #333333;
          position: absolute;
          top: -8px;
          right: 9px; }
    .section-7 .process__main {
      display: flex;
      position: relative;
      padding: 0 30px 0 40px; }
      @media (max-width: 1000.98px) {
        .section-7 .process__main {
          padding: 0; } }
      @media (max-width: 420px) {
        .section-7 .process__main {
          flex-direction: column;
          justify-content: center;
          align-items: center; } }
      .section-7 .process__main__content {
        padding-top: 50px;
        padding-right: 40px;
        flex: 1; }
        @media (max-width: 1000.98px) {
          .section-7 .process__main__content {
            padding-top: 0;
            padding-right: 0; } }
        .section-7 .process__main__content--title {
          font-size: 2.8rem;
          margin-bottom: 9px; }
          @media (max-width: 420px) {
            .section-7 .process__main__content--title {
              font-size: 24px; } }
        .section-7 .process__main__content--sub {
          font-size: 18px;
          line-height: 1.4;
          font-weight: 300;
          text-align: justify; }
          @media (max-width: 1000.98px) {
            .section-7 .process__main__content--sub {
              padding-right: 40px; } }
          @media (max-width: 420px) {
            .section-7 .process__main__content--sub {
              font-size: 16px;
              padding-right: 0;
              margin-bottom: 10px; } }
      .section-7 .process__main img {
        margin-top: -20px; }
        @media (max-width: 1000.98px) {
          .section-7 .process__main img {
            width: 152px;
            height: 152px;
            margin-top: 0; } }
  .section-7 .support {
    position: relative;
    margin-top: 80px;
    padding: 40px 80px;
    background-color: #fff;
    border-radius: 20px;
    width: 100%;
    height: auto; }
    @media (max-width: 1000.98px) {
      .section-7 .support {
        margin-top: 64px;
        padding: 60px 24px; } }
    .section-7 .support__title {
      margin-bottom: 24px;
      text-align: center;
      font-size: 3.2rem; }
      @media (max-width: 1000.98px) {
        .section-7 .support__title {
          font-size: 2.8rem; } }
    .section-7 .support__text {
      position: relative;
      z-index: 2;
      margin-bottom: 24px;
      font-size: 1.8rem;
      line-height: 1.6; }
    .section-7 .support__sub-title {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      font-size: 2rem; }
      @media (max-width: 1000.98px) {
        .section-7 .support__sub-title {
          margin-bottom: 0; } }
      .section-7 .support__sub-title::before {
        content: '';
        margin-right: 4px;
        display: block;
        border-radius: 100vh;
        background-color: #FAE12E;
        width: 18px;
        height: 18px; }
    .section-7 .support__content {
      margin-bottom: 40px;
      display: flex;
      gap: 2%; }
      @media (max-width: 1000.98px) {
        .section-7 .support__content {
          flex-wrap: wrap;
          margin-bottom: 24px; } }
      .section-7 .support__content__text {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px #000;
        border-radius: 100vh;
        width: 23.5%;
        height: 48px; }
        @media (max-width: 1000.98px) {
          .section-7 .support__content__text {
            margin-top: 16px;
            width: 48%;
            height: 40px; } }
        .section-7 .support__content__text::before {
          position: absolute;
          top: 0;
          left: 16px;
          transform: translateY(-50%);
          padding: 0 4px;
          background-color: #fff;
          font-family: 'Roboto', sans-serif;
          font-size: 2.4rem;
          font-weight: 500;
          line-height: 1; }
        .section-7 .support__content__text.support-1::before {
          content: '1'; }
        .section-7 .support__content__text.support-2::before {
          content: '2'; }
        .section-7 .support__content__text.support-3::before {
          content: '3'; }
        .section-7 .support__content__text.support-4::before {
          content: '4'; }
        .section-7 .support__content__text p {
          position: relative;
          z-index: 0;
          padding: 0 4px;
          width: fit-content;
          font-size: 1.8rem;
          font-weight: 500; }
          @media (max-width: 1000.98px) {
            .section-7 .support__content__text p {
              font-size: 1.5rem; } }
          .section-7 .support__content__text p::after {
            content: '';
            z-index: -1;
            left: 0;
            bottom: 0;
            position: absolute;
            background-color: #FAE12E;
            width: 100%;
            height: 40%; }
    .section-7 .support__conversion {
      width: 100%; }
      .section-7 .support__conversion__text {
        display: flex;
        margin: 0 auto 16px auto;
        width: fit-content;
        font-size: 1.8rem; }
        @media (max-width: 1000.98px) {
          .section-7 .support__conversion__text {
            margin: 0 auto 8px auto; } }
        .section-7 .support__conversion__text::before {
          content: '';
          display: block;
          transform: rotate(-35deg);
          margin-right: 16px;
          background-color: #000;
          width: 1px;
          height: 36px; }
        .section-7 .support__conversion__text::after {
          content: '';
          display: block;
          transform: rotate(35deg);
          margin-left: 16px;
          background-color: #000;
          width: 1px;
          height: 36px; }
      .section-7 .support__conversion__button {
        position: relative;
        z-index: 0;
        width: 100%;
        height: 64px; }
        .section-7 .support__conversion__button::after {
          content: '';
          position: absolute;
          bottom: 0;
          display: block;
          border-radius: 4px;
          z-index: -1;
          width: 100%;
          height: 56px;
          background-color: #D8C228; }
        .section-7 .support__conversion__button a {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #FAE12E;
          border-radius: 4px;
          width: 100%;
          height: 56px;
          color: #000;
          font-size: 2rem;
          font-weight: 900; }
    .section-7 .support__check {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 40px;
      transform: translateY(-56px);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #FAE12E;
      border-radius: 100vh;
      width: 200px;
      height: 200px; }
      @media (max-width: 1000.98px) {
        .section-7 .support__check {
          left: 0;
          transform: translateX(-15px) translateY(-50%);
          width: 95px;
          height: 95px; } }
      .section-7 .support__check p {
        position: relative;
        transform: translateY(-8px);
        line-height: 1.1;
        font-weight: 700;
        font-size: 1.8rem; }
        @media (max-width: 1000.98px) {
          .section-7 .support__check p {
            transform: unset;
            line-height: 1.2;
            text-align: center;
            font-size: 1.4rem; } }
        .section-7 .support__check p span {
          margin-top: 8px;
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 4.2rem; }
          @media (max-width: 1000.98px) {
            .section-7 .support__check p span {
              font-size: 2.5rem; } }
        .section-7 .support__check p::after {
          content: url("../assets/images/support-arrow.png");
          position: absolute;
          left: 0;
          bottom: -4px; }
          @media (max-width: 1000.98px) {
            .section-7 .support__check p::after {
              display: none; } }

.section-8 {
  width: 100%;
  background-color: #fff;
  padding: 130px 0 120px 0; }
  @media (max-width: 1000.98px) {
    .section-8 {
      padding: 50px 0 120px 0; } }
  .section-8 .qa-wrapper {
    margin-top: 30px;
    width: 100%; }
  .section-8 .qa-item {
    width: 100%; }
    .section-8 .qa-item:not(:last-child) {
      margin-bottom: 40px; }
    .section-8 .qa-item--q {
      padding-left: 88px;
      background-color: #FAE12E;
      padding-top: 19px;
      height: 70px;
      font-size: 2rem;
      font-weight: bold;
      position: relative;
      text-align: justify; }
      @media (max-width: 1000.98px) {
        .section-8 .qa-item--q {
          font-size: 16px;
          padding-left: 60px;
          height: auto;
          min-height: 37px;
          padding-bottom: 10px;
          padding-top: 10px;
          padding-right: 10px; } }
      .section-8 .qa-item--q.light {
        background-color: #FFF084; }
      .section-8 .qa-item--q::before {
        position: absolute;
        content: "q";
        text-transform: uppercase;
        font-size: "Montserrat", sans-serif;
        font-size: 4rem;
        top: 15px;
        left: 35px; }
        @media (max-width: 1000.98px) {
          .section-8 .qa-item--q::before {
            font-size: 3rem;
            left: 25px;
            top: 8px; } }
    .section-8 .qa-item--a {
      padding-left: 88px;
      padding-top: 19px;
      font-size: 2rem;
      font-weight: 400;
      padding-right: 50px;
      position: relative;
      text-align: justify; }
      @media (max-width: 1000.98px) {
        .section-8 .qa-item--a {
          font-size: 16px;
          padding-left: 60px;
          padding-top: 10px; } }
      @media (max-width: 434.98px) {
        .section-8 .qa-item--a {
          padding-right: 10px; } }
      .section-8 .qa-item--a::before {
        position: absolute;
        content: "a";
        text-transform: uppercase;
        font-size: "Montserrat", sans-serif;
        font-size: 4rem;
        top: 15px;
        left: 35px; }
        @media (max-width: 1000.98px) {
          .section-8 .qa-item--a::before {
            font-size: 3rem;
            left: 25px; } }

.section-taikenkai {
  padding: 80px 0;
  background-color: #FCF9ED;
  width: 100%;
  height: auto; }
  .section-taikenkai .container {
    max-width: 840px; }
  .section-taikenkai__box-1 {
    margin: 0 auto 24px auto;
    width: 420px; }
    @media (max-width: 1000.98px) {
      .section-taikenkai__box-1 {
        width: 100%; } }
    .section-taikenkai__box-1 img {
      display: flex;
      width: 100%;
      height: auto; }
    .section-taikenkai__box-1 h2 {
      margin-top: 20px;
      text-align: center; }
  .section-taikenkai__text-1 {
    margin-bottom: 24px;
    text-align: center;
    font-size: 1.8rem; }
    @media (max-width: 1000.98px) {
      .section-taikenkai__text-1 {
        text-align: left; } }
  .section-taikenkai__benefits {
    margin-bottom: 40px; }
    .section-taikenkai__benefits h3 {
      position: relative;
      z-index: 0;
      margin: 0 auto;
      width: fit-content;
      color: #DB5C51;
      font-weight: 700;
      font-size: 32px;
      line-height: 1; }
      @media (max-width: 1000.98px) {
        .section-taikenkai__benefits h3 {
          font-size: 28px; } }
      .section-taikenkai__benefits h3::after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        width: calc(100% + 48px);
        height: 3px; }
    .section-taikenkai__benefits__detail {
      margin-top: -16px;
      margin-bottom: 8px;
      padding: 48px 24px 32px 24px;
      border-radius: 10px;
      border: solid 3px #DB5C51;
      background-color: #fff;
      width: 100%; }
      @media (max-width: 1000.98px) {
        .section-taikenkai__benefits__detail {
          margin-top: -14px;
          padding: 32px 12px 24px 12px; } }
      .section-taikenkai__benefits__detail__text {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        font-weight: 700; }
        @media (max-width: 1000.98px) {
          .section-taikenkai__benefits__detail__text {
            flex-direction: column;
            gap: 5px; } }
        .section-taikenkai__benefits__detail__text span {
          margin: 0 4px;
          padding: 4px;
          font-size: 34px;
          background-color: #fae120; }
    .section-taikenkai__benefits__caution {
      text-align: center; }
  .section-taikenkai__reserve {
    width: 100%; }
    .section-taikenkai__reserve__title {
      margin-bottom: 32px;
      position: relative;
      background-color: #FAE12E;
      padding: 16px;
      text-align: center;
      font-size: 2.4rem; }
      .section-taikenkai__reserve__title::after {
        content: '';
        right: 50%;
        bottom: -17px;
        transform: translateX(50%);
        clip-path: polygon(100% 0, 0 0, 50% 100%);
        position: absolute;
        display: block;
        background-color: #FAE12E;
        width: 34px;
        height: 18px; }
    .section-taikenkai__reserve__text {
      margin-bottom: 16px;
      font-size: 1.8;
      line-height: 1.3; }
    .section-taikenkai__reserve__button {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      @media (max-width: 1000.98px) {
        .section-taikenkai__reserve__button {
          flex-direction: column; } }
      .section-taikenkai__reserve__button a {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FAE12E;
        border-radius: 5px;
        width: 32%;
        height: 60px;
        color: #000;
        font-size: 2rem;
        font-weight: 700; }
        @media (max-width: 1000.98px) {
          .section-taikenkai__reserve__button a {
            margin-bottom: 16px;
            padding-bottom: 8px;
            width: 100%; } }
        .section-taikenkai__reserve__button a::after {
          content: '';
          position: absolute;
          right: 0;
          border: 0;
          box-shadow: 0px 8px #D8C22B;
          border-radius: 5px;
          width: 100%;
          height: 100%; }

.rental {
  padding: 80px 0;
  background-color: #57D2FD; }
  @media (max-width: 1000.98px) {
    .rental {
      padding: 40px 0; } }
  .rental h2 {
    position: relative;
    width: fit-content;
    margin: 0 auto 40px;
    padding: 8px 16px;
    border: solid 3px #fff;
    color: #fff;
    font-size: 4.8rem;
    line-height: 1; }
    @media (max-width: 1000.98px) {
      .rental h2 {
        margin: 0 auto 32px;
        padding: 16px 8px;
        border: solid 2px #fff;
        font-size: 2.8rem; } }
    .rental h2::before {
      content: "Rental Plan !";
      position: absolute;
      top: 0;
      left: -5px;
      transform: translateY(-95%);
      font-size: 3.2rem;
      font-family: "Roboto", sans-serif;
      font-weight: 400; }
      @media (max-width: 1000.98px) {
        .rental h2::before {
          font-size: 2.4rem; } }
  .rental__image {
    width: 100%;
    height: auto; }
    .rental__image--01 {
      max-width: 670px;
      margin: 0 auto;
      margin-bottom: 40px;
      padding-bottom: 40px;
      border-bottom: dashed 3px #fff; }
      @media (max-width: 1000.98px) {
        .rental__image--01 {
          width: 80vw;
          padding-bottom: 32px;
          margin-bottom: 32px; } }
  .rental img {
    width: 100%;
    height: auto; }
    @media (max-width: 1000.98px) {
      .rental img.pc {
        display: none; } }
    .rental img.sp {
      display: none; }
      @media (max-width: 1000.98px) {
        .rental img.sp {
          display: block; } }
  .rental__text {
    width: 100%;
    height: auto;
    margin-bottom: 24px;
    color: #fff; }
    .rental__text__text-1 {
      position: relative;
      width: fit-content;
      margin: 0 auto 4px;
      font-size: 2.8rem;
      font-weight: 700;
      font-feature-settings: "palt";
      letter-spacing: 0.08em; }
      @media (max-width: 1000.98px) {
        .rental__text__text-1 {
          margin: 0 auto 8px; } }
      .rental__text__text-1::before, .rental__text__text-1::after {
        content: "";
        position: absolute;
        width: 2px;
        height: 32px;
        background-color: #fff; }
      .rental__text__text-1::before {
        top: 2px;
        left: -20px;
        transform: rotate(-35deg); }
      .rental__text__text-1::after {
        top: 2px;
        right: -20px;
        transform: rotate(35deg); }
    .rental__text__text-2 {
      width: fit-content;
      margin: 0 auto 8px;
      padding-bottom: 4px;
      border-bottom: solid 8px #FAE12E;
      font-size: 4rem;
      font-weight: 700; }
      @media (max-width: 1000.98px) {
        .rental__text__text-2 {
          margin: 0 auto 16px;
          text-align: center;
          line-height: 1.4; } }
      .rental__text__text-2 br {
        display: none; }
        @media (max-width: 1000.98px) {
          .rental__text__text-2 br {
            display: block; } }
    .rental__text__text-3 {
      width: fit-content;
      margin: 0 auto 32px;
      font-size: 2.6rem;
      font-weight: 700; }
      @media (max-width: 1000.98px) {
        .rental__text__text-3 {
          margin: 0 auto 16px;
          font-size: 2.4rem; } }
    .rental__text__text-4 {
      width: fit-content;
      margin: 0 auto;
      font-size: 3.2rem;
      font-weight: 700; }
      .rental__text__text-4::after {
        content: url("../assets/images/arrow.svg");
        display: block;
        width: 72px;
        height: auto;
        margin: 32px auto 0; }
        @media (max-width: 1000.98px) {
          .rental__text__text-4::after {
            margin: 24px auto 0; } }
  .rental__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 64px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 8px 0 0 #DDC93B;
    background-color: #FAE12E;
    color: #000;
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: 0.08em; }

.section-contact {
  width: 100%;
  background-color: #F5F5F4;
  padding: 130px 0;
  min-height: 600px; }
  @media (max-width: 1000.98px) {
    .section-contact {
      padding: 50px 0 85px  0; } }
  .section-contact--title {
    font-size: 6rem;
    line-height: 6.8rem;
    margin-bottom: 40px; }
    @media (max-width: 1000.98px) {
      .section-contact--title {
        font-size: 4.8rem;
        line-height: 3.8rem; } }
  .section-contact__main {
    width: 840px; }
    @media (max-width: 1000.98px) {
      .section-contact__main {
        width: 100%; } }
    .section-contact__main--note {
      margin-bottom: 24px;
      font-size: 1.8rem;
      line-height: 1.4; }

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; }
  @media (max-width: 1000.98px) {
    .form-group {
      margin-bottom: 20px; } }
  .form-group--error {
    font-size: 14px;
    color: red; }
  .form-group.required label {
    display: flex;
    align-items: center;
    margin-bottom: 12px; }
    .form-group.required label::after {
      content: "必須";
      display: block;
      margin-left: 8px;
      background-color: #DD5347;
      color: #fff;
      font-size: 12px;
      padding: 4px 6px; }
  .form-group label {
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    width: fit-content;
    position: relative; }
  .form-group input, .form-group textarea {
    border: 1px solid #DDDDDD;
    padding: 10px;
    border-radius: 5px;
    color: #333333;
    font-size: 16px;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: 300; }
    .form-group input::placeholder, .form-group textarea::placeholder {
      font-size: 16px;
      color: #CCCCCC;
      font-family: "Noto Sans JP", sans-serif;
      font-weight: 300; }
    .form-group input:focus, .form-group textarea:focus {
      outline: none; }

.accept-privacy {
  font-size: 16px;
  text-align: center;
  margin-bottom: 35px;
  margin-top: 35px; }
  .accept-privacy a {
    color: #1571DA; }
  @media (max-width: 420px) {
    .accept-privacy {
      font-size: 14px; } }

.form-step-1.out {
  display: none; }

.form-step-2 {
  display: none; }
  .form-step-2--title {
    margin-bottom: 16px;
    text-align: center;
    font-size: 4.8rem; }
    @media (max-width: 500px) {
      .form-step-2--title {
        font-size: 3.2rem; } }
  .form-step-2.in {
    display: flex; }

.confirm-form {
  width: 100%;
  background-color: #fff;
  padding: 75px 40px;
  border-radius: 10px;
  margin-bottom: 40px;
  margin-top: 40px; }
  @media (max-width: 434.98px) {
    .confirm-form {
      padding: 40px 20px;
      margin-top: 20px; } }
  .confirm-form--item:not(:last-child) {
    margin-bottom: 20px; }
  .confirm-form--item p:nth-child(1) {
    font-size: 18px;
    font-weight: bold; }
  .confirm-form--item p:nth-child(2) {
    font-size: 16px;
    font-weight: 300;
    white-space: pre-line; }

.thanks-form__main {
  padding: 70px 60px;
  background-color: #fff;
  margin-top: 40px; }
  @media (max-width: 434.98px) {
    .thanks-form__main {
      padding: 40px 20px; } }

.thanks-form--title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  line-height: 32px; }

.thanks-form--p {
  font-size: 18px;
  font-weight: 300;
  margin: 16px 0;
  text-align: justify;
  line-height: 32px; }

.thanks-form__phone--f {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 8px; }

.thanks-form__phone .section-contact__main--tel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center; }

.thanks-form__phone img {
  display: block;
  margin-right: 8px; }

.thanks-form__phone .tel {
  display: block;
  font-size: 4rem;
  font-family: "Montserrat", sans-serif; }

.thanks-form__phone a.tel {
  color: #000;
  text-decoration: none; }

.thanks-form__phone .time {
  display: block;
  margin-top: 4px;
  width: 100%;
  font-weight: 700; }

.bt-l {
  margin-bottom: 40px; }
  @media (max-width: 1000.98px) {
    .bt-l {
      margin-bottom: 20px; } }

.voice {
  background-color: #fff;
  width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  padding-top: 80px;
  padding-bottom: 120px; }
  @media (max-width: 1099px) {
    .voice {
      width: 100%;
      max-width: unset; } }
  .voice .section-title {
    text-align: center;
    margin-bottom: 40px; }
  .voice .voice-main {
    margin: 0 auto;
    width: 90%;
    max-width: 1000px;
    height: auto;
    display: flex;
    margin-bottom: 60px; }
    @media (max-width: 1099px) {
      .voice .voice-main {
        flex-direction: column; } }
    .voice .voice-main:last-child {
      margin-bottom: 0; }
  .voice .voice-img {
    display: flex;
    width: 100%;
    max-width: 380px;
    line-height: 1; }
    @media (max-width: 1099px) {
      .voice .voice-img {
        max-width: unset; } }
    .voice .voice-img .img-pc {
      width: 100%;
      height: auto; }
      @media (max-width: 1099px) {
        .voice .voice-img .img-pc {
          display: none; } }
    .voice .voice-img .img-sp {
      width: 100%;
      height: auto; }
      @media (min-width: 1100px) {
        .voice .voice-img .img-sp {
          display: none; } }
  .voice .voice-description {
    width: 100%;
    height: auto;
    padding-left: 24px;
    padding-top: 20px; }
    @media (max-width: 1099px) {
      .voice .voice-description {
        padding-top: 0;
        padding-left: 0; } }
    .voice .voice-description .voice-text1 {
      font-size: 'Roboto';
      font-size: 2.4rem;
      font-weight: 700;
      letter-spacing: 0.12em;
      position: relative;
      margin-bottom: 16px; }
      @media (max-width: 1099px) {
        .voice .voice-description .voice-text1 {
          display: none; } }
      .voice .voice-description .voice-text1::after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: #000;
        display: block;
        position: absolute;
        top: 50%;
        z-index: -1; }
      .voice .voice-description .voice-text1 p {
        display: inline-block;
        background-color: #fff;
        padding-right: 16px;
        z-index: 2; }
    .voice .voice-description .voice-title-pc {
      width: calc(100% + 40px);
      padding-top: 8px;
      padding-right: 24px;
      padding-bottom: 8px;
      padding-left: 40px;
      font-size: 2.8rem;
      letter-spacing: 0.08em;
      font-weight: 700;
      background-color: #FAE12E;
      margin-left: -40px;
      margin-bottom: 16px;
      font-feature-settings: "palt";
      line-height: 1.6; }
      @media (max-width: 1099px) {
        .voice .voice-description .voice-title-pc {
          display: none; } }
    .voice .voice-description .voice-text2 {
      font-size: 1.6rem;
      line-height: 1.5;
      text-align: justify;
      margin-bottom: 24px;
      letter-spacing: 0.06em; }
      @media (max-width: 1099px) {
        .voice .voice-description .voice-text2 {
          padding-right: 5%;
          padding-left: 5%;
          font-size: 16px; } }
    .voice .voice-description .voice-text3 {
      font-size: 1.8rem;
      letter-spacing: 0.1em;
      text-align: right;
      font-weight: 700; }
      @media (max-width: 1099px) {
        .voice .voice-description .voice-text3 {
          padding-right: 5%;
          padding-left: 5%;
          font-size: 18px; } }
    .voice .voice-description .voice-title-sp {
      width: 100%;
      height: auto;
      padding-top: 16px;
      padding-right: 16px;
      padding-bottom: 24px;
      padding-left: 16px;
      background-color: #FAE12E;
      text-align: center;
      font-weight: 700;
      font-size: 2.8rem;
      letter-spacing: 0.08em;
      line-height: 1.6;
      margin-bottom: 24px; }
      @media (min-width: 1100px) {
        .voice .voice-description .voice-title-sp {
          display: none; } }
      @media (max-width: 389px) {
        .voice .voice-description .voice-title-sp {
          font-size: 2.4rem; } }
    .voice .voice-description .voice-text-sp {
      font-family: 'Roboto', sans-serif;
      font-size: 1.8rem;
      font-weight: 700;
      position: relative;
      z-index: 2; }
      .voice .voice-description .voice-text-sp::after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: #000;
        display: block;
        position: absolute;
        top: 50%;
        z-index: -1; }
      .voice .voice-description .voice-text-sp p {
        display: inline;
        padding-right: 16px;
        padding-left: 16px;
        background-color: #FAE12E; }
